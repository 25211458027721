import clientConfig from '../../../../../../client-config';
const ProgramsUrl = clientConfig.ProgramsUrl;
const FullPath = `${ProgramsUrl}/progrmas/PLAY_MACHINES_1/BuildingInstructions_Images/ExplorationModels`

const Pic1 = `${FullPath}/SpinningTopSpeedIncreasing/24Gear/1_1x.png`;
const Pic2 = `${FullPath}/SpinningTopSpeedIncreasing/24Gear/2_1x.png`;
const Pic3 = `${FullPath}/SpinningTopSpeedIncreasing/24Gear/3_1x.png`;
const Pic4 = `${FullPath}/SpinningTopSpeedIncreasing/24Gear/4_1x.png`;
const Pic5 = `${FullPath}/SpinningTopSpeedIncreasing/24Gear/5_1x.png`;
const Pic6 = `${FullPath}/SpinningTopSpeedIncreasing/24Gear/6_1x.png`;
const Pic7 = `${FullPath}/SpinningTopSpeedIncreasing/24Gear/7_1x.png`;




export const Buildingimages = [

]
    const Pics = [
        Pic1,
        Pic2,
        Pic3,
        Pic4,
        Pic5,
        Pic6,
        Pic7,
    ]


    for (let i = 0; i < Pics.length; i++) {
        let obj = {};
        obj['original'] = Pics[i];
        obj['thumbnail'] = Pics[i];
        Buildingimages.push(obj);
    }

export default {
    Buildingimages,
}