import { observable, configure, runInAction, action, autorun, computed } from 'mobx';
import parse from 'html-react-parser'
import axios from 'axios';
import clientConfig from '../client-config';

configure({ enforceActions: true });




class Pages {
    @observable.ref TermsOfUse = []
    @observable loading = false

    // @action
 
    // LoadPageData(id) {
    //     // console.log(this.TermsOfUse)
     
    //     axios.get(`${wordPressSiteUrl}/wp-json/wp/v2/pages/${id}`)
    //         .then(res => {
    //             let myResponse = res.data.content.rendered;
    //             runInAction(() => {

    //                 switch (id) {
    //                     case "51":  // the Number is Page id in wordpress
    //                         this.TermsOfUse = parse(myResponse)
    //                         Object.assign(this.TermsOfUse, myResponse.data)
    //                         // console.log(res)
    //                         this.loading = false
    //                         return null

    //                     default:
    //                         this.loading = false
    //                         return null

    //                 }

    //             });
    //         })
    //         .catch(err => {
    //             this.loading = false
    //         })
    // }


    constructor() {
        this.LoadPageData('51', false)
    }





    // ID = PAGE ID in wordpress
    LoadPageData(id, showLoader) {
        // console.log(this.TermsOfUse)

        const wordPressSiteUrl = clientConfig.siteUrl;

        showLoader ? this.loading = true : this.loading = false
        // PUT IN PAGE THAT SHOW CONTENT
        // import Loader from "../loader.gif";
        // {this.props.pages.loading && <img className="loader" src={Loader} alt="Loader" />}

        axios.get(`${wordPressSiteUrl}/wp-json/wp/v2/pages/${id}`)
            .then(res => {
                let myResponse = res.data.content.rendered;
                runInAction(() => {

                    switch (id) {
                        case "51":  // the Number is Page id in wordpress
                            this.TermsOfUse = parse(myResponse)
                            Object.assign(this.TermsOfUse, myResponse.data)
                            // console.log(res)
                            this.loading = false
                            return null

                        default:
                            this.loading = false
                            return null

                    }

                });
            })
            .catch(err => {
                this.loading = false
            })

    }






}
export default new Pages();





