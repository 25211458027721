import React from 'react';
import { NavLink } from "react-router-dom";
import {isAdministrator} from './functions';
/**
 * Reach Routers gives us access to a function called getProps.
 * Whatever is returned by getProps(), in this case style,
 * will be applied to the Link attribute as props.
 * So here {...props} will be replaced by style: {}
 *
 * @param props
 * @return {*}
 * @constructor
 */



const NavLinkA = props => (
	<div className= {"NavLinkMobile " + props.className}>
	<NavLink
		{...props}
		activeStyle={{ color: isAdministrator() ? 'rgb(255, 200, 0)' : '#BAD9EF'  }}
		// getProps={({ isCurrent }) => ( { style: { color: isCurrent ? '#BAD9EF' : '#FFFFFF' } } )}
		className="nav-link"
	/>
	</div>
);

export default NavLinkA;
