import usersList from './UsersList'
import pages from './Pages'
import Programs from './Programs'
import Lessons from './Lessons'

const stores = {
    usersList,
    pages,
    Programs,
    Lessons
};

export default stores