import React from 'react';
import styles from '../../SingleProgram/Programs.module.css';
import ModelInstructionsTabContent from './ModelInstructionsTabContent'
import ExperimentInstructionsTabContent from './ExperimentInstructionsTabContent'
import { withRouter } from 'react-router-dom'

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';



class PlayMachines2 extends React.Component {

    render() {

        return (
            <div className={styles.FlexContainer}>
                <Tabs className={styles.Tabs} >
                    <TabList>
                        <Tab>Main Models</Tab>
                        <Tab>Experimental Models</Tab>
                    </TabList>

                    <TabPanel>

                        <ModelInstructionsTabContent/>

                    </TabPanel>
                    <TabPanel>
                        <ExperimentInstructionsTabContent/>
                    </TabPanel>
                </Tabs>
            </div>

        );
    }
}

export default withRouter(PlayMachines2);