
import React from 'react';
import styles from '../../../screens/SingleProgram/Programs.module.css';
import Lessonlitem from '../Lessonlitem';
import { getUserName } from "../../../components/functions";


import clientConfig from '../../../client-config';
const ProgramsUrl = clientConfig.ProgramsUrl;
const FullPath = `${ProgramsUrl}/progrmas/PLAY_MACHINES_2/SmallPics/MainModels`

const LawnMower = `${FullPath}/Lawn-Mower.png`;
const FortuneWheel = `${FullPath}/Fortune-Wheel.png`;
const ScissorLift = `${FullPath}/Scissor-Lift.png`;
const WormRobot = `${FullPath}/Worm-Robot.png`;
const Ballerina = `${FullPath}/Ballerina.png`;
const FamilyRide = `${FullPath}/Family-Ride.png`;
const OutdoorSwing = `${FullPath}/Outdoor-Swing.png`;
const ContinuousTurningFan = `${FullPath}/Continuous-Turning-Fan.png`;
const AlibabaAmusementRide = `${FullPath}/Alibaba.png`;
const PortableClawMachine = `${FullPath}/Portable-Claw-Machine.png`;

class ModelInstructionsTabContent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ManuIsOpen: false,
        }
    }

    render() {
        const userName = (getUserName()) ? getUserName() : '';
        const programName = 'PlayMachines1';

        return (
            <div className={styles.FlexContainer}>


                <div className={styles.ItemsContainer}>

                    {/* ITEM TO LOOP FROM DATA BASE */}
                    <Lessonlitem
                        Title="Lawn Mower"
                        LinkTo="https://support.youngengineers.org/portal/kb/articles/lesson-1-lawn-mower"
                        Lesson="Lesson 1"
                        ModelPic={LawnMower}
                        TitleBackColor='blue'
                    // localStorageItemName='Lawn-Mower'
                    // userName={userName}
                    // programName={programName}
                    />
                    {/* ITEM TO LOOP FROM DATA BASE */}



                    {/* ITEM TO LOOP FROM DATA BASE */}
                    <Lessonlitem
                        Title="Fortune Wheel"
                        LinkTo="https://support.youngengineers.org/portal/kb/articles/lesson-2-fortune-wheel"
                        Lesson="Lesson 2"
                        ModelPic={FortuneWheel}
                        TitleBackColor='blue'
                    />
                    {/* ITEM TO LOOP FROM DATA BASE */}


                    {/* ITEM TO LOOP FROM DATA BASE */}
                    <Lessonlitem
                        Title="Scissor Lift"
                        LinkTo="https://support.youngengineers.org/portal/kb/articles/lesson-3-scissor-lift"
                        Lesson="Lesson 3"
                        ModelPic={ScissorLift}
                        TitleBackColor='blue'
                    />
                    {/* ITEM TO LOOP FROM DATA BASE */}



                    {/* ITEM TO LOOP FROM DATA BASE */}
                    <Lessonlitem
                        Title="Worm Robot"
                        LinkTo="https://support.youngengineers.org/portal/kb/articles/lesson-4-worm-robot"
                        Lesson="Lesson 4"
                        ModelPic={WormRobot}
                        TitleBackColor='blue'
                    />
                    {/* ITEM TO LOOP FROM DATA BASE */}

                    {/* ITEM TO LOOP FROM DATA BASE */}
                    <Lessonlitem
                        Title="Ballerina"
                        LinkTo="https://support.youngengineers.org/portal/kb/articles/lesson-5-ballerina"
                        Lesson="Lesson 5"
                        ModelPic={Ballerina}
                        TitleBackColor='blue'
                    />
                    {/* ITEM TO LOOP FROM DATA BASE */}


                    {/* ITEM TO LOOP FROM DATA BASE */}
                    <Lessonlitem
                        Title="Tea Cup Family Ride"
                        LinkTo="https://support.youngengineers.org/portal/kb/articles/lesson-6-tea-cup-family-ride"
                        Lesson="Lesson 6"
                        ModelPic={FamilyRide}
                        TitleBackColor='blue'
                    />
                    {/* ITEM TO LOOP FROM DATA BASE */}


                    {/* ITEM TO LOOP FROM DATA BASE */}
                    <Lessonlitem
                        Title="Outdoor Swing"
                        LinkTo="https://support.youngengineers.org/portal/kb/articles/lesson-7-outdoor-swing"
                        Lesson="Lesson 7"
                        ModelPic={OutdoorSwing}
                        TitleBackColor='blue'
                    />
                    {/* ITEM TO LOOP FROM DATA BASE */}


                    {/* ITEM TO LOOP FROM DATA BASE */}
                    <Lessonlitem
                        Title="Continuous Turning Fan"
                        LinkTo="https://support.youngengineers.org/portal/kb/articles/lesson-8-continuous-turning-fan"
                        Lesson="Lesson 8"
                        ModelPic={ContinuousTurningFan}
                        TitleBackColor='blue'
                    />
                    {/* ITEM TO LOOP FROM DATA BASE */}


                    {/* ITEM TO LOOP FROM DATA BASE */}
                    <Lessonlitem
                        Title="Alibaba Amusement Ride"
                        LinkTo="https://support.youngengineers.org/portal/kb/articles/lesson-9-alibaba-amusement-ride"
                        Lesson="Lesson 9"
                        ModelPic={AlibabaAmusementRide}
                        TitleBackColor='blue'
                    />
                    {/* ITEM TO LOOP FROM DATA BASE */}

                    {/* ITEM TO LOOP FROM DATA BASE */}
                    <Lessonlitem
                        Title="Portable Claw Machine"
                        LinkTo="https://support.youngengineers.org/portal/kb/articles/lesson-10-portable-claw-machine"
                        Lesson="Lesson 10"
                        ModelPic={PortableClawMachine}
                        TitleBackColor='blue'
                    />
                    {/* ITEM TO LOOP FROM DATA BASE */}



                </div>


            </div>
        );
    }
}

export default ModelInstructionsTabContent;