import clientConfig from '../../../../../../client-config';
const ProgramsUrl = clientConfig.ProgramsUrl;
const FullPath = `${ProgramsUrl}/progrmas/PLAY_MACHINES_2/BuildingInstructions_Images/ExplorationModels`

const Pic1 = `${FullPath}/GrippingMechanism/1_1x.png`;
const Pic2 = `${FullPath}/GrippingMechanism/2_1x.png`;
const Pic3 = `${FullPath}/GrippingMechanism/3_1x.png`;
const Pic4 = `${FullPath}/GrippingMechanism/4_1x.png`;
const Pic5 = `${FullPath}/GrippingMechanism/5_1x.png`;
const Pic6 = `${FullPath}/GrippingMechanism/6_1x.png`;
const Pic7 = `${FullPath}/GrippingMechanism/7_1x.png`;
const Pic8 = `${FullPath}/GrippingMechanism/8_1x.png`;
const Pic9 = `${FullPath}/GrippingMechanism/9_1x.png`;



export const Buildingimages = [

]
    const Pics = [
        Pic1,
        Pic2,
        Pic3,
        Pic4,
        Pic5,
        Pic6,
        Pic7,
        Pic8,
        Pic9,
 

    ]


    for (let i = 0; i < Pics.length; i++) {
        let obj = {};
        obj['original'] = Pics[i];
        obj['thumbnail'] = Pics[i];
        Buildingimages.push(obj);
    }

export default {
    Buildingimages,
}