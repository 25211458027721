import React from 'react';
import styles from '../screens/SingleProgram/Programs.module.css';

import { withRouter } from 'react-router-dom'

import ImageGallery from 'react-image-gallery';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import SmallPerimeter from '../screens/SingleProgram/PLAY_MACHINES_1/BuildingInstructions_Images/ExplorationModels/ImagesLIstGenerator/SmallPerimeterImages'


class PlayMachines1 extends React.Component {

    render() {

        return (
            <div className="tabContainer">
              
                    <Tabs>
                        <TabList>
                            <Tab>{this.props.FirsTabsTitle}</Tab>
                            <Tab>{this.props.SecondTabsTitle}</Tab>
                            <Tab>{this.props.ThirdTabsTitle}</Tab>
                        </TabList>

                        <TabPanel>

                            <ImageGallery items={this.props.FirstTabImages} ClassName={styles.GalleryContainer} infinite={false} />

                        </TabPanel>
                        <TabPanel>
                            <ImageGallery items={this.props.SecondTabImages} ClassName={styles.GalleryContainer} infinite={false} />

                        </TabPanel>
                        <TabPanel>
                            <ImageGallery items={this.props.ThirdTabImages} ClassName={styles.GalleryContainer} infinite={false} />

                        </TabPanel>
                    </Tabs>
               
            </div>
        );
    }
}

export default withRouter(PlayMachines1);