import React, { Component } from 'react';
import styles from '../../../screens/BuildingInstructions.module.css';
import ImageGallery from 'react-image-gallery';
import GalleryWithTabs from '../../../components/GalleryWithTabs';


// LIST OF LINKS GENERATOR
import { Buildingimages as CatapultImages } from '../PLAY_MACHINES_1/BuildingInstructions_Images/MainModels/ImagesLIstGenerator/CatapultImages';
import { Buildingimages as DeliveryBoxImages } from '../PLAY_MACHINES_1/BuildingInstructions_Images/MainModels/ImagesLIstGenerator/DeliveryBoxImages';
import { Buildingimages as FlyingChairsImages } from '../PLAY_MACHINES_1/BuildingInstructions_Images/MainModels/ImagesLIstGenerator/FlyingChairsImages';
import { Buildingimages as GearboxRideControlImages } from '../PLAY_MACHINES_1/BuildingInstructions_Images/MainModels/ImagesLIstGenerator/GearboxRideControlImages';
import { Buildingimages as MixerImages } from '../PLAY_MACHINES_1/BuildingInstructions_Images/MainModels/ImagesLIstGenerator/MixerImages';
import { Buildingimages as PortableSkyWheelRideImages } from '../PLAY_MACHINES_1/BuildingInstructions_Images/MainModels/ImagesLIstGenerator/PortableSkyWheelRideImages';
import { Buildingimages as SeesawImages } from '../PLAY_MACHINES_1/BuildingInstructions_Images/MainModels/ImagesLIstGenerator/SeesawImages';
import { Buildingimages as SlowFastFanImages } from '../PLAY_MACHINES_1/BuildingInstructions_Images/MainModels/ImagesLIstGenerator/SlowFastFanImages';
import { Buildingimages as SpinningTopLauncherImages } from '../PLAY_MACHINES_1/BuildingInstructions_Images/MainModels/ImagesLIstGenerator/SpinningTopLauncherImages';
import { Buildingimages as TurningBasketImages } from '../PLAY_MACHINES_1/BuildingInstructions_Images/MainModels/ImagesLIstGenerator/TurinigTargetImages';

import { Buildingimages as CentrifugalForceImages } from '../PLAY_MACHINES_1/BuildingInstructions_Images/ExplorationModels/ImagesLIstGenerator/CentrifugalForceImages';
import { Buildingimages as DoubleSpeedIncreasingTransmissionImages } from '../PLAY_MACHINES_1/BuildingInstructions_Images/ExplorationModels/ImagesLIstGenerator/DoubleSpeedIncreasingTransmissionImages';
import { Buildingimages as DoubleSpeedDecreasingTransmission } from '../PLAY_MACHINES_1/BuildingInstructions_Images/ExplorationModels/ImagesLIstGenerator/DoubleSpeedReductionTransmissionImages';
import { Buildingimages as FastAndSlowTransmission } from '../PLAY_MACHINES_1/BuildingInstructions_Images/ExplorationModels/ImagesLIstGenerator/FastAndSlowTransmissionImages';
import { Buildingimages as GearboxImages } from '../PLAY_MACHINES_1/BuildingInstructions_Images/ExplorationModels/ImagesLIstGenerator/GearboxImages';
import { Buildingimages as MechanicalCamImages } from '../PLAY_MACHINES_1/BuildingInstructions_Images/ExplorationModels/ImagesLIstGenerator/MechanicalCamImages';

import { Buildingimages as SmallPerimeterImages } from '../PLAY_MACHINES_1/BuildingInstructions_Images/ExplorationModels/ImagesLIstGenerator/SmallPerimeterImages';
import { Buildingimages as BigPerimeterImages } from '../PLAY_MACHINES_1/BuildingInstructions_Images/ExplorationModels/ImagesLIstGenerator/BigPerimeterImages';
import { Buildingimages as SlidingImages } from '../PLAY_MACHINES_1/BuildingInstructions_Images/ExplorationModels/ImagesLIstGenerator/SlidingImages';

import { Buildingimages as _8Gear } from '../PLAY_MACHINES_1/BuildingInstructions_Images/ExplorationModels/ImagesLIstGenerator/8GearImages';
import { Buildingimages as _24Gear } from '../PLAY_MACHINES_1/BuildingInstructions_Images/ExplorationModels/ImagesLIstGenerator/24GearImages';
import { Buildingimages as _40Gear } from '../PLAY_MACHINES_1/BuildingInstructions_Images/ExplorationModels/ImagesLIstGenerator/40GearImages';
import { Buildingimages as SimpleGearTransmission } from '../PLAY_MACHINES_1/BuildingInstructions_Images/ExplorationModels/ImagesLIstGenerator/SimpleGearTransmission';




class AllSingleModelsContent extends Component {

    handleSlide = (event) => {
        var currentIndex = parseInt(this._imageGallery.getCurrentIndex());
        var ToalIndex = parseInt(this._imageGallery.props.items.length);

        if (ToalIndex === currentIndex + 1) {
            // console.log('END')
        }

    }


    render() {
        const modelNameNoSpaces = this.props.ModelNameNoSpaces

        const ModelName = () => {
            switch (modelNameNoSpaces) {
                case 'Catapult':
                    return <ImageGallery useBrowserFullscreen={false} useBrowserFullscreen={false} items={CatapultImages} ClassName={styles.GalleryContainer} infinite={false} ref={i => this._imageGallery = i} onSlide={this.handleSlide.bind(this)} />;
                case 'DeliveryBox':
                    return <ImageGallery useBrowserFullscreen={false} items={DeliveryBoxImages} ClassName={styles.GalleryContainer} />;
                case 'FlyingChairs':
                    return <ImageGallery useBrowserFullscreen={false} items={FlyingChairsImages} ClassName={styles.GalleryContainer} infinite={false} />;
                case 'GearboxRideControl':
                    return <ImageGallery useBrowserFullscreen={false} items={GearboxRideControlImages} ClassName={styles.GalleryContainer} infinite={false} />;

                case 'Mixer':
                    return <ImageGallery useBrowserFullscreen={false} items={MixerImages} ClassName={styles.GalleryContainer} infinite={false} />;

                case 'PortableSkyWheel':
                    return <ImageGallery useBrowserFullscreen={false} items={PortableSkyWheelRideImages} ClassName={styles.GalleryContainer} infinite={false} />;

                case 'Seesaw':
                    return <ImageGallery useBrowserFullscreen={false} items={SeesawImages} ClassName={styles.GalleryContainer} infinite={false} />;

                case 'SlowFastFan':
                    return <ImageGallery useBrowserFullscreen={false} items={SlowFastFanImages} ClassName={styles.GalleryContainer} infinite={false} />;

                case 'SpinningTopLauncher':
                    return <ImageGallery useBrowserFullscreen={false} items={SpinningTopLauncherImages} ClassName={styles.GalleryContainer} infinite={false} />;

                case 'TurningBasket':
                    return <ImageGallery useBrowserFullscreen={false} items={TurningBasketImages} ClassName={styles.GalleryContainer} infinite={false} />;

                case 'CentrifugalForce':
                    return <ImageGallery useBrowserFullscreen={false} items={CentrifugalForceImages} ClassName={styles.GalleryContainer} infinite={false} />;

                case 'DoubleSpeedIncreasingTransmission':
                    return <ImageGallery useBrowserFullscreen={false} items={DoubleSpeedIncreasingTransmissionImages} ClassName={styles.GalleryContainer} infinite={false} />;

                case 'DoubleSpeedDecreasingTransmission':
                    return <ImageGallery useBrowserFullscreen={false} items={DoubleSpeedDecreasingTransmission} ClassName={styles.GalleryContainer} infinite={false} />;

                case 'FastandSlowTransmission':
                    return <ImageGallery useBrowserFullscreen={false} items={FastAndSlowTransmission} ClassName={styles.GalleryContainer} infinite={false} />;

                case 'Gearbox':
                    return <ImageGallery useBrowserFullscreen={false} items={GearboxImages} ClassName={styles.GalleryContainer} infinite={false} />;

                case 'MechanicalCam':
                    return <ImageGallery useBrowserFullscreen={false} items={MechanicalCamImages} ClassName={styles.GalleryContainer} infinite={false} />;

                case 'Perimeter,RollingAndSliding':
                    return <GalleryWithTabs
                        FirsTabsTitle="Small Perimeter"
                        FirstTabImages={SmallPerimeterImages}
                        SecondTabsTitle="Big Perimeter"
                        SecondTabImages={BigPerimeterImages}
                        ThirdTabsTitle="Sliding"
                        ThirdTabImages={SlidingImages}
                    />
                     
                case 'SpeedIncreasingTransmission':
                    return <GalleryWithTabs
                        FirsTabsTitle="8 Gear"
                        FirstTabImages={_8Gear}
                        SecondTabsTitle="24 Gear"
                        SecondTabImages={_24Gear}
                        ThirdTabsTitle="40 Gear"
                        ThirdTabImages={_40Gear}
                    />

                case 'SimpleGearTransmission':
                    return <ImageGallery useBrowserFullscreen={false} items={SimpleGearTransmission} ClassName={styles.GalleryContainer} infinite={false} />;

                default:
                    return null;
            }
        }





        return (

            ModelName()

        );


    }
}

export default AllSingleModelsContent;
