import clientConfig from '../../../../../../client-config';
const ProgramsUrl = clientConfig.ProgramsUrl;
const FullPath = `${ProgramsUrl}/progrmas/PLAY_MACHINES_1/BuildingInstructions_Images/MainModels`

const Pic1 = `${FullPath}/TurinigTarget/1_1x.png`;
const Pic2 = `${FullPath}/TurinigTarget/2_1x.png`;
const Pic3 = `${FullPath}/TurinigTarget/3_1x.png`;
const Pic4 = `${FullPath}/TurinigTarget/4_1x.png`;
const Pic5 = `${FullPath}/TurinigTarget/5_1x.png`;
const Pic6 = `${FullPath}/TurinigTarget/6_1x.png`;
const Pic7 = `${FullPath}/TurinigTarget/7_1x.png`;
const Pic8 = `${FullPath}/TurinigTarget/8_1x.png`;
const Pic9 = `${FullPath}/TurinigTarget/9_1x.png`;
const Pic10 = `${FullPath}/TurinigTarget/10_1x.png`;
const Pic11 = `${FullPath}/TurinigTarget/11_1x.png`;
const Pic12 = `${FullPath}/TurinigTarget/12_1x.png`;
const Pic13 = `${FullPath}/TurinigTarget/13_1x.png`;
const Pic14 = `${FullPath}/TurinigTarget/14_1x.png`;
const Pic15 = `${FullPath}/TurinigTarget/15_1x.png`;
const Pic16 = `${FullPath}/TurinigTarget/16_1x.png`;
const Pic17 = `${FullPath}/TurinigTarget/17_1x.png`;
const Pic18 = `${FullPath}/TurinigTarget/18_1x.png`;
const Pic19 = `${FullPath}/TurinigTarget/19_1x.png`;
const Pic20 = `${FullPath}/TurinigTarget/20_1x.png`;
const Pic21 = `${FullPath}/TurinigTarget/21_1x.png`;
const Pic22 = `${FullPath}/TurinigTarget/21_1x.png`;
const Pic23 = `${FullPath}/TurinigTarget/23_1x.png`;
const Pic24 = `${FullPath}/TurinigTarget/24_1x.png`;
const Pic25 = `${FullPath}/TurinigTarget/25_1x.png`;




export const Buildingimages = [

]
    const Pics = [
        Pic1,
        Pic2,
        Pic3,
        Pic4,
        Pic5,
        Pic6,
        Pic7,
        Pic8,
        Pic9,
        Pic10,
        Pic11,
        Pic12,
        Pic13,
        Pic14,
        Pic15,
        Pic16,
        Pic17,
        Pic18,
        Pic19,
        Pic20,
        Pic21,
        Pic22,
        Pic23,
        Pic24,
        Pic25,
    
    ]


    for (let i = 0; i < Pics.length; i++) {
        let obj = {};
        obj['original'] = Pics[i];
        obj['thumbnail'] = Pics[i];
        Buildingimages.push(obj);
    }

export default {
    Buildingimages,
}