
import React from 'react';
import styles from '../../../screens/SingleProgram/Programs.module.css';
import Modelitem from '../Modelitem';
import { getUserName } from "../../../components/functions";

import clientConfig from '../../../client-config';
const ProgramsUrl = clientConfig.ProgramsUrl;
const FullPath = `${ProgramsUrl}/progrmas/PLAY_MACHINES_2/SmallPics/ExplorationModels`

const BarsOscillationMechanism = `${FullPath}/Bars-Oscillation-mechanism.png`;
const ContinuousBackAndForth = `${FullPath}/Continuous-BackAndForth.png`;
const GravityQuickReturnMechanism = `${FullPath}/Gravity-quick-return-mechanism.png`;
const GrippingMechanism = `${FullPath}/Gripping-Mechanism.png`;
const KinematicChain = `${FullPath}/Kinematic-chain.png`;
const MultipleInputGear = `${FullPath}/Multiple-Input-Gear.png`;
const OscillationMechanism3BarMechanism = `${FullPath}/Oscillation-mechanism-3-bar-mechanism.png`;
const Ratchet = `${FullPath}/Ratchet.png`;
const WormGearStud = `${FullPath}/Worm-gear-stud.png`;
const FastAndSlowTransmission = `${FullPath}/FastAndSlowTransmission.png`;


class ExperimentInstructionsTabContent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ManuIsOpen: false,
        }
    }

    render() {
        const userName = (getUserName()) ? getUserName() : '';
        const programName = 'PlayMachines2';

        return (
            <div className={styles.FlexContainer}>



                <div className={styles.ItemsContainer}>

                    {/* 1 ITEM TO LOOP FROM DATA BASE */}
                    <Modelitem
                        Title="Fastand Slow Transmission "
                        TopPosition='0px'
                        ModelPic={FastAndSlowTransmission}
                        Password='5445'
                        userName={userName}
                        programName={programName}
                        TitleBackColor='gray'
                        localStorageItemName='FastandSlowTransmission'
                    />
                    {/* ITEM TO LOOP FROM DATA BASE */}


                    {/*2 ITEM TO LOOP FROM DATA BASE */}
                    <Modelitem
                        Title="Ratchet"
                        TopPosition='0px'
                        ModelPic={Ratchet}
                        Password='8486'
                        userName={userName}
                        programName={programName}
                        TitleBackColor='gray'
                        localStorageItemName='Ratchet'
                    />
                    {/* ITEM TO LOOP FROM DATA BASE */}



                    {/* 3 ITEM TO LOOP FROM DATA BASE */}
                    <Modelitem
                        Title="Kinematic Chain"
                        TopPosition='0px'
                        ModelPic={KinematicChain}
                        Password='1332'
                        userName={userName}
                        programName={programName}
                        TitleBackColor='gray'
                        localStorageItemName='KinematicChain'
                    />
                    {/* ITEM TO LOOP FROM DATA BASE */}


                    {/* 4 ITEM TO LOOP FROM DATA BASE */}
                    <Modelitem
                        Title="Worm Gear"
                        TopPosition='0px'
                        ModelPic={WormGearStud}
                        Password='2233'
                        userName={userName}
                        programName={programName}
                        TitleBackColor='gray'
                        localStorageItemName='WormGear'
                    />
                    {/* ITEM TO LOOP FROM DATA BASE */}

                    {/* 5 ITEM TO LOOP FROM DATA BASE */}
                    <Modelitem
                        Title="Continuous Back And Forth"
                        TopPosition='0px'
                        ModelPic={ContinuousBackAndForth}
                        Password='6456'
                        userName={userName}
                        programName={programName}
                        TitleBackColor='gray'
                        localStorageItemName='ContinuousBackAndForth'
                    />
                    {/* ITEM TO LOOP FROM DATA BASE */}

                    {/* 6 ITEM TO LOOP FROM DATA BASE */}
                    <Modelitem
                        Title="Multiple Output Gear"
                        TopPosition='0px'
                        ModelPic={MultipleInputGear}
                        Password='2244'
                        userName={userName}
                        programName={programName}
                        TitleBackColor='gray'
                        localStorageItemName='MultipleOutputGear'
                    />
                    {/* ITEM TO LOOP FROM DATA BASE */}

                    {/* 7 ITEM TO LOOP FROM DATA BASE */}
                    <Modelitem
                        Title="3-Bars Oscillation Mechanism"
                        TopPosition='0px'
                        ModelPic={OscillationMechanism3BarMechanism}
                        Password='1331'
                        userName={userName}
                        programName={programName}
                        TitleBackColor='gray'
                        localStorageItemName='3-BarsOscillationMechanism'
                    />
                    {/* ITEM TO LOOP FROM DATA BASE */}


                    {/* 8 ITEM TO LOOP FROM DATA BASE */}
                    <Modelitem
                        Title="4-Bars Oscillation Mechanism"
                        TopPosition='0px'
                        ModelPic={BarsOscillationMechanism}
                        Password='8984'
                        userName={userName}
                        programName={programName}
                        TitleBackColor='gray'
                        localStorageItemName='4-BarsOscillationMechanism'
                    />
                    {/* ITEM TO LOOP FROM DATA BASE */}



                    {/* 9 ITEM TO LOOP FROM DATA BASE */}
                    <Modelitem
                        Title="Gravity Quick Return Mechanism"
                        TopPosition='0px'
                        ModelPic={GravityQuickReturnMechanism}
                        Password='1389'
                        userName={userName}
                        programName={programName}
                        TitleBackColor='gray'
                        localStorageItemName='GravityQuickReturnMechanism'
                    />
                    {/* ITEM TO LOOP FROM DATA BASE */}

                    {/* 10 ITEM TO LOOP FROM DATA BASE */}
                    <Modelitem
                        Title="Gripping Mechanism "
                        TopPosition='0px'
                        ModelPic={GrippingMechanism}
                        Password='1358'
                        userName={userName}
                        programName={programName}
                        TitleBackColor='gray'
                        localStorageItemName='GrippingMechanism'
                    />
                    {/* ITEM TO LOOP FROM DATA BASE */}









                </div>


            </div>
        );
    }
}

export default ExperimentInstructionsTabContent;