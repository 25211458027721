import React, { Component } from 'react';
import styles from '../../../screens/BuildingInstructions.module.css';
import ImageGallery from 'react-image-gallery';
import GalleryWithTabs from '../../../components/GalleryWithTabs';


// LIST OF LINKS GENERATOR
import { Buildingimages as LawnMower } from '../PLAY_MACHINES_2/BuildingInstructions_Images/MainModels/ImagesLIstGenerator/LawnMowerImages';
import { Buildingimages as FortuneWheel } from '../PLAY_MACHINES_2/BuildingInstructions_Images/MainModels/ImagesLIstGenerator/FourtuneWheelImages';
import { Buildingimages as ScissorLift } from '../PLAY_MACHINES_2/BuildingInstructions_Images/MainModels/ImagesLIstGenerator/ScissorLiftImages';
import { Buildingimages as WormRobot } from '../PLAY_MACHINES_2/BuildingInstructions_Images/MainModels/ImagesLIstGenerator/WormRobotImages';
import { Buildingimages as Ballerina } from '../PLAY_MACHINES_2/BuildingInstructions_Images/MainModels/ImagesLIstGenerator/BallerinaImages';
import { Buildingimages as TeaCupFamilyRide } from '../PLAY_MACHINES_2/BuildingInstructions_Images/MainModels/ImagesLIstGenerator/TeaCupFamilyRideImages';
import { Buildingimages as OutdoorSwing } from '../PLAY_MACHINES_2/BuildingInstructions_Images/MainModels/ImagesLIstGenerator/OutdoorSwingImages';
import { Buildingimages as ContinuousTurningFan } from '../PLAY_MACHINES_2/BuildingInstructions_Images/MainModels/ImagesLIstGenerator/ContinuousTurningFanImages';
import { Buildingimages as AlibabaAmusementRide } from '../PLAY_MACHINES_2/BuildingInstructions_Images/MainModels/ImagesLIstGenerator/AlibabaAmusementRideImages';
import { Buildingimages as PortableClawMachine } from '../PLAY_MACHINES_2/BuildingInstructions_Images/MainModels/ImagesLIstGenerator/PortableClawMachineImages';

import { Buildingimages as FastandSlowTransmission } from '../PLAY_MACHINES_2/BuildingInstructions_Images/ExplorationModels/ImagesLIstGenerator/FastAndSlowTransmissionImages';
import { Buildingimages as Ratchet } from '../PLAY_MACHINES_2/BuildingInstructions_Images/ExplorationModels/ImagesLIstGenerator/RatchetImages';
import { Buildingimages as KinematicChain } from '../PLAY_MACHINES_2/BuildingInstructions_Images/ExplorationModels/ImagesLIstGenerator/KinematicChainImages';
import { Buildingimages as WormGear } from '../PLAY_MACHINES_2/BuildingInstructions_Images/ExplorationModels/ImagesLIstGenerator/WormGearImages';
import { Buildingimages as ContinuousBackAndForth } from '../PLAY_MACHINES_2/BuildingInstructions_Images/ExplorationModels/ImagesLIstGenerator/ContinuousBackandForthImages';
import { Buildingimages as MultipleOutputGear } from '../PLAY_MACHINES_2/BuildingInstructions_Images/ExplorationModels/ImagesLIstGenerator/MultipleInputGearImages';
import { Buildingimages as _3BarsOscillationMechanism } from '../PLAY_MACHINES_2/BuildingInstructions_Images/ExplorationModels/ImagesLIstGenerator/3BarsOscillationMechanismImages';
import { Buildingimages as _4BarsOscillationMechanismImages} from '../PLAY_MACHINES_2/BuildingInstructions_Images/ExplorationModels/ImagesLIstGenerator/4BarsOscillationMechanismImages';
import { Buildingimages as GravityQuickReturnMechanism} from '../PLAY_MACHINES_2/BuildingInstructions_Images/ExplorationModels/ImagesLIstGenerator/GravityQuickReturnMmechanismImages';
import { Buildingimages as GrippingMechanism} from '../PLAY_MACHINES_2/BuildingInstructions_Images/ExplorationModels/ImagesLIstGenerator/GrippingMechanismImages';


class AllSingleModelsContent extends Component {

    render() {
        const modelNameNoSpaces = this.props.ModelNameNoSpaces

        const ModelName = () => {
            switch (modelNameNoSpaces) {
                case 'LawnMower':
                    return <ImageGallery items={LawnMower} ClassName={styles.GalleryContainer} infinite={false} />;
                case 'FortuneWheel':
                    return <ImageGallery items={FortuneWheel} ClassName={styles.GalleryContainer} infinite={false} />;
                case 'ScissorLift':
                    return <ImageGallery items={ScissorLift} ClassName={styles.GalleryContainer} infinite={false} />;
                case 'WormRobot':
                    return <ImageGallery items={WormRobot} ClassName={styles.GalleryContainer} infinite={false} />;
                case 'Ballerina':
                    return <ImageGallery items={Ballerina} ClassName={styles.GalleryContainer} infinite={false} />;
                case 'TeaCupFamilyRide':
                    return <ImageGallery items={TeaCupFamilyRide} ClassName={styles.GalleryContainer} infinite={false} />;
                case 'OutdoorSwing':
                    return <ImageGallery items={OutdoorSwing} ClassName={styles.GalleryContainer} infinite={false} />;
                case 'ContinuousTurningFan':
                    return <ImageGallery items={ContinuousTurningFan} ClassName={styles.GalleryContainer} infinite={false} />;
                case 'AlibabaAmusementRide':
                    return <ImageGallery items={AlibabaAmusementRide} ClassName={styles.GalleryContainer} infinite={false} />;
                case 'PortableClawMachine':
                    return <ImageGallery items={PortableClawMachine} ClassName={styles.GalleryContainer} infinite={false} />;

                case 'FastandSlowTransmission':
                    return <ImageGallery items={FastandSlowTransmission} ClassName={styles.GalleryContainer} infinite={false} />;
                case 'Ratchet':
                    return <ImageGallery items={Ratchet} ClassName={styles.GalleryContainer} infinite={false} />;
                case 'KinematicChain':
                    return <ImageGallery items={KinematicChain} ClassName={styles.GalleryContainer} infinite={false} />;
                case 'WormGear':
                    return <ImageGallery items={WormGear} ClassName={styles.GalleryContainer} infinite={false} />;
                case 'ContinuousBackAndForth':
                    return <ImageGallery items={ContinuousBackAndForth} ClassName={styles.GalleryContainer} infinite={false} />;
                case 'MultipleOutputGear':
                    return <ImageGallery items={MultipleOutputGear} ClassName={styles.GalleryContainer} infinite={false} />;
                case '3-BarsOscillationMechanism':
                    return <ImageGallery items={_3BarsOscillationMechanism} ClassName={styles.GalleryContainer} infinite={false} />;
                case '4-BarsOscillationMechanism':
                    return <ImageGallery items={_4BarsOscillationMechanismImages} ClassName={styles.GalleryContainer} infinite={false} />;
                case 'GravityQuickReturnMechanism':
                    return <ImageGallery items={GravityQuickReturnMechanism} ClassName={styles.GalleryContainer} infinite={false} />;
                case 'GrippingMechanism':
                    return <ImageGallery items={GrippingMechanism} ClassName={styles.GalleryContainer} infinite={false} />;
            

                // case 'SpeedIncreadingTransmission':
                //     return <GalleryWithTabs
                //         FirsTabsTitle="8 Gear"
                //         FirstTabImages={_8Gear}
                //         SecondTabsTitle="24 Gear"
                //         SecondTabImages={_24Gear}
                //         ThirdTabsTitle="40 Gear"
                //         ThirdTabImages={_40Gear}
                //     />
                default:
                    return null;
            }
        }





        return (

            ModelName()

        );


    }
}

export default AllSingleModelsContent;
