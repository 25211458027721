
import React from 'react';
import styles from './SelectModelContent.module.css';
import ModelitemLoader from './ModelitemLoader';



class SelectModelContent extends React.Component {
 



    render() {

     

        return (
            <div className={styles.FlexContainer}>


                <div className={styles.ItemsContainer}>
                      <ModelitemLoader />
                      <ModelitemLoader />
                      <ModelitemLoader />
                      <ModelitemLoader />
                      <ModelitemLoader />
                      <ModelitemLoader />
                      <ModelitemLoader />
                      <ModelitemLoader />
                      <ModelitemLoader />
                      <ModelitemLoader />

                </div>


            </div>
        );
    }
}

export default SelectModelContent;