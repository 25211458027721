import clientConfig from '../../../../../../client-config';
const ProgramsUrl = clientConfig.ProgramsUrl;
const FullPath = `${ProgramsUrl}/progrmas/PLAY_MACHINES_1/BuildingInstructions_Images/MainModels`

const Pic1 = `${FullPath}/Catapult/1_1x.png`;
const Pic2 = `${FullPath}/Catapult/2_1x.png`;
const Pic3 = `${FullPath}/Catapult/3_1x.png`;
const Pic4 = `${FullPath}/Catapult/4_1x.png`;
const Pic5 = `${FullPath}/Catapult/5_1x.png`;
const Pic6 = `${FullPath}/Catapult/6_1x.png`;
const Pic7 = `${FullPath}/Catapult/7_1x.png`;
const Pic8 = `${FullPath}/Catapult/8_1x.png`;
const Pic9 = `${FullPath}/Catapult/9_1x.png`;
const Pic10 = `${FullPath}/Catapult/10_1x.png`;
const Pic11 = `${FullPath}/Catapult/11_1x.png`;
const Pic12 = `${FullPath}/Catapult/12_1x.png`;
const Pic13 = `${FullPath}/Catapult/13_1x.png`;
const Pic14 = `${FullPath}/Catapult/14_1x.png`;
const Pic15 = `${FullPath}/Catapult/15_1x.png`;
const Pic16 = `${FullPath}/Catapult/16_1x.png`;
const Pic17 = `${FullPath}/Catapult/17_1x.png`;
const Pic18 = `${FullPath}/Catapult/18_1x.png`;
const Pic19 = `${FullPath}/Catapult/19_1x.png`;
const Pic20 = `${FullPath}/Catapult/20_1x.png`;
const Pic21 = `${FullPath}/Catapult/21_1x.png`;

export const Buildingimages = []

const Pics = [
    Pic1,
    Pic2,
    Pic3,
    Pic4,
    Pic5,
    Pic6,
    Pic7,
    Pic8,
    Pic9,
    Pic10,
    Pic11,
    Pic12,
    Pic13,
    Pic14,
    Pic15,
    Pic16,
    Pic17,
    Pic18,
    Pic19,
    Pic20,
    Pic21,
]


for (let i = 0; i < Pics.length; i++) {
    let obj = {};
    obj['original'] = Pics[i];
    obj['thumbnail'] = Pics[i];
    Buildingimages.push(obj);
}

export default {
    Buildingimages,
}