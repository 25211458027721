import React from 'react';
import NavLink from './NavLink';
import styles from './Navbar.module.css';
import { getUserName } from "./functions";
import { slide as Menu } from 'react-burger-menu';

import './Navbar.css';
import Radium from 'radium';
import BackgroungUrl from '../images/Header_teacher.jpg';
import MobileBackgroungUrl from '../images/Header_teacher_Mobile.jpg';

import { withRouter, Link } from "react-router-dom";

class Navbar extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			ManuIsOpen: false,
		}
	}


	handleLogout = () => {
		localStorage.clear();
		window.location.href = '/';
	};

	render() {
		const userName = (getUserName()) ? getUserName() : '';

		const BackgroundStyle = {
			backgroundImage: 'url(' + BackgroungUrl + ')',

			//On moble screen
			'@media (max-width: 801px)': {
				backgroundImage: 'url(' + MobileBackgroungUrl + ')',
				backgroundSize: 'contain',
				backgroundRepeat: 'no-repeat',
			}

		};

		return (
			<>
				<Menu right>
					<NavLink to={`/students_list/${userName}`}>Students List</NavLink>
					<NavLink to={`/sign_up_new_student/${userName}`}>Sign Up New Student</NavLink>
					<NavLink to={`/building_instructions/${userName}`}>Building Instructions</NavLink>
					<NavLink to={`/lesson_plans/${userName}`}>Lesson Plans</NavLink><br></br>
					<NavLink to={`/terms_of_use`}>Terms Of Use</NavLink>
					<div className='LogOutMobile NavLinkMobile' onClick={this.handleLogout} >Log out</div>
				</Menu>

				<div className={styles.NavigationContainer}>



					<div style={BackgroundStyle} className={styles.NavBar} >
						<div className={styles.LeftDiv}>
							<div className={styles.userName}>
								{userName}
								{/* {userName}'s dashboard */}
							</div>
						</div>
						<div className={styles.CenterDiv}>

							<NavLink to={`/students_list/${userName}`}>Students List</NavLink>
							<NavLink to={`/sign_up_new_student/${userName}`}>Sign Up New Student</NavLink>
							<NavLink to={`/building_instructions/${userName}`}>Building Instructions</NavLink>
							<NavLink to={`/lesson_plans/${userName}`}>Lesson Plans</NavLink>

						</div>
						<div className={styles.RightDiv}>

							<div>
								<button onClick={this.handleLogout} className={styles.LogOutBt + ' logoutBtn'}>Log Out</button>
								<NavLink className="TermsNavLink" to={`/terms_of_use`}>Terms Of Use</NavLink>
							</div>

						</div>
					</div>
				</div>

				<div className={styles.CenterItemOutOfHeader}>
                    <div className={styles.HeaderLinks}>
                        <Link to={"/"} className={styles.StudentGoBackOutOfHeader}>{this.props.Home}</Link>
                        <div onClick={() => this.props.history.goBack()} className={styles.StudentGoBackOutOfHeader}>{this.props.GoBack}</div>
                    </div>
                    <div className={styles.StudentTitleOutOfHeader}>{this.props.Title}</div>
                </div>
				
			</>
		);
	}
}

export default withRouter(Radium(Navbar));


