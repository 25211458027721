import React from 'react';
import styles from './Navbar.module.css';
import { getUserName } from "./functions";
import { slide as Menu } from 'react-burger-menu'
import NavLink from './NavLink';
import { withRouter, Link } from "react-router-dom";

import { PopupMenu } from 'react-rectangle-popup-menu';
import { GoGear } from "react-icons/go";
import { RiAccountCircleLine, RiStoreLine } from "react-icons/ri"
import { AiOutlineMail, AiTwotoneBuild } from "react-icons/ai";
import { FiGlobe, FiHome } from "react-icons/fi";
import { FaRegListAlt, FaChalkboardTeacher } from "react-icons/fa";
import { GrUserAdd } from "react-icons/gr";

import PopUpLink from '../components/Navbars/PopUpLink'
import PopUpLinkHref from '../components/Navbars/PopUpLinkHref'

import './Navbar.css'
import Radium from 'radium';
import BackgroungUrl from '../images/Header_kids.jpg';
import MobileBackgroungUrl from '../images/Header_kidsMobile.jpg';

class Navbar extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            ManuIsOpen: false,
        }
    }


    handleLogout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('userName');
        localStorage.removeItem('userId');
        localStorage.removeItem('userRole');
        window.location.href = '/';
    };


    render() {
        const userName = (getUserName()) ? getUserName() : '';

        const BackgroundStyle = {
            backgroundImage: 'url(' + BackgroungUrl + ')',

            //On moble screen
            '@media (max-width: 801px)': {
                backgroundImage: 'url(' + MobileBackgroungUrl + ')',
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
            }

        };

        return (
            <>

                <Menu className={styles.MobileMenu} customBurgerIcon={<GoGear className={styles.GearIcon} />} right >

                    <PopUpLink
                        LinkTo={`/building_instructions/${userName}`}
                        Icon={<AiTwotoneBuild />} Left="0px" FontSize="24px" Top='4px' Text="Building Instructions" />


                    <div className={styles.PopUpLogOutContainer}>
                        <div className={styles.PopUpLogOut} onClick={this.handleLogout} >Log out</div>
                    </div>

                    <div className={styles.PopUpTermsContainer}>
                        <NavLink className={styles.PopUpLink} to={`/terms_of_use`}>
                            <div className={styles.PopUpTerms}>Terms of use</div>
                        </NavLink>
                    </div>
                </Menu>

                <div className={styles.NavigationContainer}>


                    <div style={BackgroundStyle} className={styles.NavBar} >
                        <div className={styles.LeftDiv}>
                            <div className={styles.StudentName}>
                                Hi {userName}
                            </div>
                        </div>
                        {/* <div className={styles.CenterDivStudent}>
                            <div className={styles.TitleAndLink}>
                                <div className={styles.StudentTitle}>{this.props.Title}</div>
                                <div onClick={() => this.props.history.goBack()} className={styles.StudentGoBack}>{this.props.GoBack}</div>
                            </div>
                        </div>
                         */}


                        <div className={styles.CenterDivStudent}>
                            <div className={styles.TitleAndLink}>
                                <div className={styles.HeaderLinksContainer}>
                                <div className={styles.HeaderLinks}>
                                    <Link title="Choose Program" style={{ display: this.props.DisplayHome }} to={"/"} className={styles.StudentGoBack}>{this.props.Home}</Link>
                                    <div title="go back" style={{ display: this.props.DisplayBack }} onClick={() => this.props.history.goBack()} className={styles.StudentGoBack}>{this.props.GoBack}</div>
                                </div>
                                </div>
                                <div className={styles.StudentTitle}>{this.props.Title}</div>

                            </div>
                        </div>



                        <div className={styles.RightDiv}>

                            <div>
                                <div className={styles.PopupMenuContainer} onClick={this.test}>
                                    <PopupMenu
                                        height='auto'
                                        width={280}

                                        direction="bottom"
                                        button={<GoGear className={styles.GearIcon} />}
                                    >


                                        <div className={styles.LinksContainer}>
                                            <PopUpLink
                                                LinkTo={`/building_instructions/${userName}`}
                                                Icon={<AiTwotoneBuild />} Left="0px" FontSize="24px" Top='4px' Text="Building Instructions" MarginTop="35px" />


                                            <div className={styles.PopUpLogOutContainer}>
                                                <div className={styles.PopUpLogOut} onClick={this.handleLogout} >Log out</div>
                                            </div>

                                            <div className={styles.PopUpTermsContainer}>
                                                <NavLink className={styles.PopUpLink} to={`/terms_of_use`}>
                                                    <div className={styles.PopUpTerms}>Terms of use</div>
                                                </NavLink>
                                            </div>

                                        </div>
                                    </PopupMenu></div>
                                {/* <NavLink className="TermsNavLink" to={`/terms_of_use`}>Terms Of Use</NavLink> */}
                            </div>
                        </div>


                    </div>
                </div>

                <div className={styles.CenterItemOutOfHeader}>
                    <div className={styles.HeaderLinks}>
                        <Link to={"/"} className={styles.StudentGoBackOutOfHeader}>{this.props.Home}</Link>
                        <div onClick={() => this.props.history.goBack()} className={styles.StudentGoBackOutOfHeader}>{this.props.GoBack}</div>
                    </div>
                    <div className={styles.StudentTitleOutOfHeader}>{this.props.Title}</div>
                </div>
            </>
        );
    }
}

export default withRouter(Radium(Navbar));


