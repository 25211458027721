import { observable, configure, runInAction, action, toJS } from 'mobx';
import parse from 'html-react-parser'
import axios from 'axios';
import clientConfig from '../client-config';


configure({ enforceActions: true });


class Programs {
    @observable loading = false
    @observable Plm1 = []
    @observable Plm2 = []
    @observable AllPrograms = []
    
    @observable FilterdHiddenPrograms = []

    @observable ProgramLogoLink = []
    @observable program_desktop_backgroung = []
    @observable program_mobile_backgroung = []


    @observable Lesson = []
    // @observable Plm1Logo = []
    @observable FatchEnd = false
    @observable ShowUpgrade = false
    @observable HideClicked = localStorage.getItem('HideClicked')



    @action FilterLogoLinks(id) {
        console.log(toJS(this.AllPrograms))
        //    const FilterData = this.AllPrograms.filter(page => page.title.rendered === "play-machines-2");
    }

    @action IsHideClicked() {
        const Hide = localStorage.getItem('HideClicked')
        return Hide
    }


    @action ShowUpgradeTrue() {
        this.ShowUpgrade = true;

    }

    @action ShowUpgradeFalse() {
        this.ShowUpgrade = false;

    }


    LoadPrograms(PageId) {

        const wordPressSiteUrl = clientConfig.siteUrl;

        this.loading = true
        axios.get(`${wordPressSiteUrl}/wp-json/wp/v2/pages?parent=127&per_page=100`)
            .then(res => {

                const AllPages = res.data
               
                AllPages.sort((a, b) => (a.metadata.order - b.metadata.order))


                const FilterHidenPrograms = res.data.filter(page => page.metadata.hide[0] == 0);
         
                if (PageId) {

                    const FilterSelectedPage = AllPages.filter(page => page.id === parseInt(PageId));
                    const ProgramLogo = FilterSelectedPage[0].program_logo[0];
                    const program_desktop_backgroung = FilterSelectedPage[0].program_desktop_backgroung[0]
                    const program_mobile_backgroung = FilterSelectedPage[0].program_mobile_backgroung[0]
                    runInAction(() => {
                        this.ProgramLogoLink = ProgramLogo
                        this.program_desktop_backgroung = program_desktop_backgroung
                        this.program_mobile_backgroung = program_mobile_backgroung

                    });
                }

                const Plm1 = res.data.filter(page => page.title.rendered === "play-machines-1");
                const Plm2 = res.data.filter(page => page.title.rendered === "play-machines-2");

                // SORT EXZAPLE
                // this.Programs = Programs.sort((a, b) => (a.metadata.program_name > b.metadata.program_name) ? 1 : -1)


                runInAction(() => {
                    this.loading = false
                    this.Plm1 = Plm1
                    this.Plm2 = Plm2
                    this.AllPrograms = AllPages
                    this.FilterdHiddenPrograms = FilterHidenPrograms
                    this.FatchEnd = true
                });
            })
            .catch(err => {
                runInAction(() => {
                    this.loading = false
                    console.log('LoadPrograms ERR')
                });
            })

    }




    constructor() {
        this.LoadPrograms()


    };



};







export default new Programs();





