import clientConfig from '../../../../../../client-config';
const ProgramsUrl = clientConfig.ProgramsUrl;
const FullPath = `${ProgramsUrl}/progrmas/PLAY_MACHINES_1/BuildingInstructions_Images/ExplorationModels`

const Pic1 = `${FullPath}/SimpleGearTransmission/1_1x.png`;
const Pic2 = `${FullPath}/SimpleGearTransmission/2_1x.png`;
const Pic3 = `${FullPath}/SimpleGearTransmission/3_1x.png`;
const Pic4 = `${FullPath}/SimpleGearTransmission/4_1x.png`;
const Pic5 = `${FullPath}/SimpleGearTransmission/5_1x.png`;
const Pic6 = `${FullPath}/SimpleGearTransmission/6_1x.png`;


export const Buildingimages = [

]
    const Pics = [
        Pic1,
        Pic2,
        Pic3,
        Pic4,
        Pic5,
        Pic6, 
      
    ]


    for (let i = 0; i < Pics.length; i++) {
        let obj = {};
        obj['original'] = Pics[i];
        obj['thumbnail'] = Pics[i];
        Buildingimages.push(obj);
    }

export default {
    Buildingimages,
}