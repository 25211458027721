import clientConfig from '../../../../../../client-config';
const ProgramsUrl = clientConfig.ProgramsUrl;
const FullPath = `${ProgramsUrl}/progrmas/PLAY_MACHINES_1/BuildingInstructions_Images/MainModels`

const Pic1 = `${FullPath}/DeliveryBox/1_1x.png`;
const Pic2 = `${FullPath}/DeliveryBox/2_1x.png`;
const Pic3 = `${FullPath}/DeliveryBox/3_1x.png`;
const Pic4 = `${FullPath}/DeliveryBox/4_1x.png`;
const Pic5 = `${FullPath}/DeliveryBox/5_1x.png`;
const Pic6 = `${FullPath}/DeliveryBox/6_1x.png`;
const Pic7 = `${FullPath}/DeliveryBox/7_1x.png`;
const Pic8 = `${FullPath}/DeliveryBox/8_1x.png`;
const Pic9 = `${FullPath}/DeliveryBox/9_1x.png`;
const Pic10 = `${FullPath}/DeliveryBox/10_1x.png`;
const Pic11 = `${FullPath}/DeliveryBox/11_1x.png`;
const Pic12 = `${FullPath}/DeliveryBox/12_1x.png`;
const Pic13 = `${FullPath}/DeliveryBox/13_1x.png`;
const Pic14 = `${FullPath}/DeliveryBox/14_1x.png`;
const Pic15 = `${FullPath}/DeliveryBox/15_1x.png`;
const Pic16 = `${FullPath}/DeliveryBox/16_1x.png`;
const Pic17 = `${FullPath}/DeliveryBox/17_1x.png`;
const Pic18 = `${FullPath}/DeliveryBox/18_1x.png`;
const Pic19 = `${FullPath}/DeliveryBox/19_1x.png`;





export const Buildingimages = [

]
    const Pics = [
        Pic1,
        Pic2,
        Pic3,
        Pic4,
        Pic5,
        Pic6,
        Pic7,
        Pic8,
        Pic9,
        Pic10,
        Pic11,
        Pic12,
        Pic13,
        Pic14,
        Pic15,
        Pic16,
        Pic17,
        Pic18,
        Pic19,
    ]


    for (let i = 0; i < Pics.length; i++) {
        let obj = {};
        obj['original'] = Pics[i];
        obj['thumbnail'] = Pics[i];
        Buildingimages.push(obj);
    }

export default {
    Buildingimages,
}