import React from 'react';
import styles from './ThSort.module.css';

import { FaSortAmountDownAlt, FaSortAmountDown } from 'react-icons/fa';


class ThSort extends React.Component {
  




    render() {




        let IconActive = ''
        if (this.props.Active) {
            IconActive = styles.fasortIconActive
        }

        let SortIcon = null;

        if (this.props.Direction) {
            SortIcon = <FaSortAmountDownAlt className={styles.fasortIcon + ' ' + IconActive} />
        }

        else {
            SortIcon = <FaSortAmountDown className={styles.fasortIcon + ' ' + IconActive} />
        }

  

        return (
            <div className={styles.FlexContainer}>
                <div>
                    {this.props.Title}
                </div>
                <div onClick={this.props.OnClick}>
                    {SortIcon}
                </div>
            </div>
        );
    }
}

export default ThSort;