
import React from 'react';
import styles from '../../../screens/SingleProgram/Programs.module.css';
import Modelitem from '../Modelitem';
import { getUserName } from "../../../components/functions";


import clientConfig from '../../../client-config';
const ProgramsUrl = clientConfig.ProgramsUrl;
const FullPath = `${ProgramsUrl}/progrmas/PLAY_MACHINES_1/SmallPics/MainModels`

const Catapult = `${FullPath}/Catapult.png`;
const DeliveryBox = `${FullPath}/DeliveryBox.png`;
const FlyingChairs = `${FullPath}/FlyingChairs.png`;
const GearboxRideControl = `${FullPath}/GearboxRideControl.png`;
const Mixer = `${FullPath}/Mixer.png`;
const PortableSkyWheel = `${FullPath}/PortableSkyWheel.png`;
const Seesaw = `${FullPath}/Seesaw.png`;
const SlowFastFan = `${FullPath}/SlowFastFan.png`;
const SpinningTopLauncher = `${FullPath}/SpinningTopLauncher.png`;
const TurningBasket = `${FullPath}/TurningBasket.png`;


class ModelInstructionsTabContent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ManuIsOpen: false,
        }
    }

    render() {
        const userName = (getUserName()) ? getUserName() : '';
        const programName = 'PlayMachines1';

        return (
            <div className={styles.FlexContainer}>


                <div className={styles.ItemsContainer}>

                    {/* ITEM TO LOOP FROM DATA BASE */}
                    <Modelitem
                        Title="Catapult"
                        TopPosition='0px'
                        ModelPic={Catapult}
                        Password='7848'
                        userName={userName}
                        programName={programName}
                        TitleBackColor='green'
                        localStorageItemName='Catapult'
                    />
                    {/* ITEM TO LOOP FROM DATA BASE */}


                    {/* ITEM TO LOOP FROM DATA BASE */}
                    <Modelitem
                        Title="Flying Chairs"
                        TopPosition='0px'
                        ModelPic={FlyingChairs}
                        Password='5455'
                        userName={userName}
                        programName={programName}
                        TitleBackColor='green'
                        localStorageItemName='FlyingChairs'
                    />
                    {/* ITEM TO LOOP FROM DATA BASE */}


                    {/* ITEM TO LOOP FROM DATA BASE */}
                    <Modelitem
                        Title="Turning Basket"
                        TopPosition='0px'
                        ModelPic={TurningBasket}
                        Password='9239'
                        userName={userName}
                        programName={programName}
                        TitleBackColor='green'
                        localStorageItemName='TurningBasket'
                    />
                    {/* ITEM TO LOOP FROM DATA BASE */}


                    {/* ITEM TO LOOP FROM DATA BASE */}
                    <Modelitem
                        Title="Delivery Box"
                        TopPosition='0px'
                        ModelPic={DeliveryBox}
                        Password='4343'
                        userName={userName}
                        programName={programName}
                        TitleBackColor='green'
                        localStorageItemName='DeliveryBox'
                    />
                    {/* ITEM TO LOOP FROM DATA BASE */}


                    {/* ITEM TO LOOP FROM DATA BASE */}
                    <Modelitem
                        Title="Slow Fast Fan"
                        TopPosition='0px'
                        ModelPic={SlowFastFan}
                        Password='3744'
                        userName={userName}
                        programName={programName}
                        TitleBackColor='green'
                        localStorageItemName='SlowFastFan'
                    />
                    {/* ITEM TO LOOP FROM DATA BASE */}


                    {/* ITEM TO LOOP FROM DATA BASE */}
                    <Modelitem
                        Title="Spinning Top Launcher"
                        TopPosition='0px'
                        ModelPic={SpinningTopLauncher}
                        Password='9877'
                        userName={userName}
                        programName={programName}
                        TitleBackColor='green'
                        localStorageItemName='SpinningTopLauncher'
                    />
                    {/* ITEM TO LOOP FROM DATA BASE */}


                    {/* ITEM TO LOOP FROM DATA BASE */}
                    <Modelitem
                        Title="Mixer"
                        TopPosition='0px'
                        ModelPic={Mixer}
                        Password='2400'
                        userName={userName}
                        programName={programName}
                        TitleBackColor='green'
                        localStorageItemName='Mixer'
                    />
                    {/* ITEM TO LOOP FROM DATA BASE */}


                    {/* ITEM TO LOOP FROM DATA BASE */}
                    <Modelitem
                        Title="Portable Sky Wheel"
                        TopPosition='0px'
                        ModelPic={PortableSkyWheel}
                        Password='0203'
                        userName={userName}
                        programName={programName}
                        TitleBackColor='green'
                        localStorageItemName='PortableSkyWheel'
                    />
                    {/* ITEM TO LOOP FROM DATA BASE */}

                    {/* ITEM TO LOOP FROM DATA BASE */}
                    <Modelitem
                        Title="Gearbox Ride Control"
                        TopPosition='0px'
                        ModelPic={GearboxRideControl}
                        Password='2344'
                        userName={userName}
                        programName={programName}
                        TitleBackColor='green'
                        localStorageItemName='GearboxRideControl'
                    />
                    {/* ITEM TO LOOP FROM DATA BASE */}


                    {/* ITEM TO LOOP FROM DATA BASE */}
                    <Modelitem
                        Title="Seesaw"
                        TopPosition='0px'
                        ModelPic={Seesaw}
                        Password='2246'
                        userName={userName}
                        programName={programName}
                        TitleBackColor='green'
                        localStorageItemName='Seesaw'
                    />
                    {/* ITEM TO LOOP FROM DATA BASE */}








                </div>


            </div>
        );
    }
}

export default ModelInstructionsTabContent;