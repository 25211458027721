import React, { Component } from 'react';

import styles from './Lesson.module.css';
import { withRouter, Redirect } from 'react-router-dom';

import { isLoggedIn, isFranchiseOwner, isAdministrator, isStudent } from "../../components/functions";


import NavbarAdmin from "../../components/NavbarAdmin2020";
import Navbar from "../../components/NavbarFranchise";
import NavbarStudents from "../../components/NavbarStudents";

import { FiHome } from "react-icons/fi";
import { FaRegArrowAltCircleLeft } from 'react-icons/fa';

import { useMediaQuery } from 'react-responsive';

import Loader from "../../loader.gif";

import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';
import { RiGalleryLine } from 'react-icons/ri';

import ImageGallery from 'react-image-gallery';
import Vimeo from '@u-wave/react-vimeo';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';


const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 801 })
    return isDesktop ? children : null
}

const Mobile = ({ children }) => {
    const isDesktop = useMediaQuery({ maxWidth: 800 })
    return isDesktop ? children : null
}



@inject('Programs', 'Lessons')
@observer // REMOVE BRACKETS => @observer
class Lesson extends Component {



    constructor() {
        super();
        this.state = {
            loaded: false,
            DesktopBackLoaded: false,
            MoboileBackLoaded: false,
        };
    }


    componentDidMount() {

        this.props.Programs.LoadPrograms(this.props.match.params.ProgramId)

        if (isStudent()) {
            const GetOpenedPrograms = JSON.parse(localStorage.getItem("OpenedPrograms"));
            if (GetOpenedPrograms) {
                const CanViewThis = GetOpenedPrograms.includes(parseInt(this.props.match.params.LessonsId))
                CanViewThis ? null : window.location.href = '/'; 
            }

            if (GetOpenedPrograms == null) {
                window.location.href = '/'
            }
        }

        this.props.Lessons.UpdateLessonData(this.props.match.params.LessonsId)

        const LessonsId = this.props.match.params.LessonsId
        if (LessonsId == 793) {
            this.props.LoginDetails.UpdateMetaItem('kit_on_header_clicked', 1)
            this.props.LoginDetails.UpdateUserMeta()
        }
    }



    resetVideo() {
        this.props.Lessons.showVideo = {}

        if (this.props.Lessons.showPlayButton) {
            this.props.Lessons.showGalleryPlayButton = true
        }

        if (this.props.Lessons.showFullscreenButton) {
            this.props.Lessons.showGalleryFullscreenButton = true
        }
    }



    SwitchDesktopBack(param) {
        if (this.props.Programs.FatchEnd) {
            switch (param) {
                case 'play-machines-1':
                    return toJS(this.props.Programs.Plm1[0].program_desktop_backgroung[0]);
                case 'play-machines-2':
                    return toJS(this.props.Programs.Plm2[0].program_desktop_backgroung[0]);
                default:
                    return toJS(this.props.SiteLinks.WhiteBackground);
            }
        }
    };


    SwitchMobileBack(param) {
        if (this.props.Programs.FatchEnd) {
            switch (param) {
                case 'play-machines-1':
                    return toJS(this.props.Programs.Plm1[0].program_mobile_backgroung[0]);
                case 'play-machines-2':
                    return toJS(this.props.Programs.Plm2[0].program_mobile_backgroung[0]);
                default:
                    return toJS(this.props.SiteLinks.WhiteBackgroundMobile);
            }
        }


    };

    Render_desktop_backgroung() {
        if (this.props.Programs.program_desktop_backgroung) {
            return <img
                style={this.state.DesktopBackLoaded ? {} : { opacity: '0' }}
                src={this.props.Programs.program_desktop_backgroung}
                alt=""
                onLoad={() => this.setState({ DesktopBackLoaded: true })}
            />

        }
    };





    Render_mobile_backgroung() {
        if (this.props.Programs.program_mobile_backgroung) {
            return <img
                style={this.state.MobileBackLoaded ? {} : { opacity: '0' }}
                src={this.props.Programs.program_mobile_backgroung}
                alt=""
                onLoad={() => this.setState({ MobileBackLoaded: true })}
            />

        }
    };





    render() {



        const ProgramName = this.props.match.params.programName

        const Title = this.props.Lessons.LessonTitle
        let Header = <Navbar />;
        let title = <div className={styles.Title}>Select Program</div>

        isFranchiseOwner() ?
            (
                isAdministrator() ? Header = <NavbarAdmin Title={Title} GoBack={<FaRegArrowAltCircleLeft />} Home={<FiHome />} /> : Header = <Navbar Title={Title} GoBack={<FaRegArrowAltCircleLeft />} Home={<FiHome />} />
            )
            : (
                Header = <NavbarStudents Title={Title} GoBack={<FaRegArrowAltCircleLeft />} Home={<FiHome />} />
            )


        isFranchiseOwner() ?
            (
                title = <div className={styles.Title}>Select Program</div>
            )
            : (
                title = <div className={styles.Title}></div>
            )



        const ShowInTabs = <Tabs className={styles.Tabs} >
            <TabList>

                {this.props.Lessons.Tab1Title ? <Tab>{this.props.Lessons.Tab1Title}</Tab> : null}
                {this.props.Lessons.Tab2Title ? <Tab>{this.props.Lessons.Tab2Title}</Tab> : null}
                {this.props.Lessons.Tab3Title ? <Tab>{this.props.Lessons.Tab3Title}</Tab> : null}
                {this.props.Lessons.Tab4Title ? <Tab>{this.props.Lessons.Tab4Title}</Tab> : null}
                {this.props.Lessons.Tab5Title ? <Tab>{this.props.Lessons.Tab5Title}</Tab> : null}

            </TabList>
            {this.props.Lessons.Tab1Title ?
                <div className={styles.TabsFlexContainer}>
                    <TabPanel className={styles.ItemsContainer}>
                        <ImageGallery
                            ref={i => this._imageGallery = i}
                            onSlide={this.props.Lessons.onSlide.bind(this)}
                            showFullscreenButton={this.props.Lessons.showGalleryFullscreenButton}
                            showPlayButton={this.props.Lessons.showPlayButton}
                            items={this.props.Lessons.Tab1Content}
                            infinite={false}
                            useBrowserFullscreen={false}

                        />
                    </TabPanel>
                </div>
                : null}


            {this.props.Lessons.Tab2Title ?
                <div className={styles.TabsFlexContainer}>
                    <TabPanel className={styles.ItemsContainer}>
                        <ImageGallery
                            ref={i => this._imageGallery = i}
                            onSlide={this.props.Lessons.onSlide.bind(this)}
                            showFullscreenButton={this.props.Lessons.showGalleryFullscreenButton}
                            showPlayButton={this.props.Lessons.showPlayButton}
                            items={this.props.Lessons.Tab2Content}
                            infinite={false}
                            useBrowserFullscreen={false}

                        />
                    </TabPanel>
                </div>
                : null}

            {this.props.Lessons.Tab3Title ?
                <div className={styles.TabsFlexContainer}>
                    <TabPanel className={styles.ItemsContainer}>
                        <ImageGallery
                            ref={i => this._imageGallery = i}
                            onSlide={this.props.Lessons.onSlide.bind(this)}
                            showFullscreenButton={this.props.Lessons.showGalleryFullscreenButton}
                            showPlayButton={this.props.Lessons.showPlayButton}
                            items={this.props.Lessons.Tab3Content}
                            infinite={false}
                            useBrowserFullscreen={false}

                        />
                    </TabPanel>
                </div>
                : null}


            {this.props.Lessons.Tab4Title ?
                <div className={styles.TabsFlexContainer}>
                    <TabPanel className={styles.ItemsContainer}>
                        <ImageGallery
                            ref={i => this._imageGallery = i}
                            onSlide={this.props.Lessons.onSlide.bind(this)}
                            showFullscreenButton={this.props.Lessons.showGalleryFullscreenButton}
                            showPlayButton={this.props.Lessons.showPlayButton}
                            items={this.props.Lessons.Tab4Content}
                            infinite={false}
                            useBrowserFullscreen={false}

                        />
                    </TabPanel>
                </div>
                : null}

            {this.props.Lessons.Tab5Title ?
                <div className={styles.TabsFlexContainer}>
                    <TabPanel className={styles.ItemsContainer}>
                        <ImageGallery
                            ref={i => this._imageGallery = i}
                            onSlide={this.props.Lessons.onSlide.bind(this)}
                            showFullscreenButton={this.props.Lessons.showGalleryFullscreenButton}
                            showPlayButton={this.props.Lessons.showPlayButton}
                            items={this.props.Lessons.Tab5Content}
                            infinite={false}
                            useBrowserFullscreen={false}

                        />
                    </TabPanel>
                </div>
                : null}
        </Tabs>;



        const ShowOneGallery = <ImageGallery
            ref={i => this._imageGallery = i}
            onSlide={this.props.Lessons.onSlide.bind(this)}
            showFullscreenButton={this.props.Lessons.showGalleryFullscreenButton}
            showPlayButton={this.props.Lessons.showPlayButton}
            items={this.props.Lessons.LessonData}
            infinite={false}
            useBrowserFullscreen={false}
        />;
        const LessonParentProgram = toJS(this.props.Lessons.LessonParentProgram)

        return (

            !isLoggedIn() ? (

                window.location.href = '/'

            ) :

                <React.Fragment>



                    <div className='PageConatiner'>
                        {Header}

                        {this.props.Lessons.loading && <img className="loader" src={Loader} alt="Loader" />}

                        <Desktop> <div className={styles.BackgroungImg}>
                            {this.Render_desktop_backgroung()}

                        </div> </Desktop>
                        <Mobile>
                            <div className={styles.MobileBackgroungImg}>
                                {this.Render_mobile_backgroung()}
                            </div> </Mobile>

                        <div className={styles.ImageGalleryContainer}>

                            {/* {console.log(this.props.Lessons.ShowInDiffrentTabs)} */}
                            {this.props.Lessons.ShowInDiffrentTabs ? ShowInTabs : ShowOneGallery}
                            {/* {ShowInTabs} */}

                            {/* {ShowOneGallery} */}

                        </div>

                    </div>
                </React.Fragment>
        );


    }
}

export default withRouter(Lesson);
