import React, { Component } from 'react';

import styles from '../fix2020/SelectModel.module.css';


import { isLoggedIn, isFranchiseOwner, isAdministrator } from "../../components/functions";


import { useMediaQuery } from 'react-responsive';

import { withRouter } from 'react-router-dom'

import NavbarAdmin from "../../components/NavbarAdmin2020";
import Navbar from "../../components/NavbarFranchise";
import NavbarStudents from "../../components/NavbarStudents";


import { FiHome } from "react-icons/fi";
import { FaRegArrowAltCircleLeft } from 'react-icons/fa';


import SelectModelContent from './SelectLessonPlansContent'
import SelectModelContentLoader from './SelectModelContentLoader'




import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';

const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 768 })
    return isDesktop ? children : null
}

const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 767 })
    return isMobile ? children : null
}




@inject('Programs', 'Lessons')
@observer // REMOVE BRACKETS => @observer
class ModelsPage extends Component {

    constructor() {
        super();
        this.state = {
            loaded: false,
            DesktopBackLoaded: false,
            MoboileBackLoaded: false,
        };
    }

    componentDidMount() {
        this.props.Programs.LoadPrograms(this.props.match.params.id)

    }

    RenderLogo() {
        if (this.props.Programs.ProgramLogoLink) {
            return <img
                style={this.state.loaded ? {} : { opacity: '0' }}
                src={this.props.Programs.ProgramLogoLink}
                alt=""
                onLoad={() => this.setState({ loaded: true })}

            />

        }
    };

    // @observable program_desktop_backgroung =[]
    // @observable program_mobile_backgroung =[]

    Render_desktop_backgroung() {
        if (this.props.Programs.program_desktop_backgroung) {
            return <img
                style={this.state.DesktopBackLoaded ? {} : { opacity: '0' }}
                src={this.props.Programs.program_desktop_backgroung}
                alt=""
                onLoad={() => this.setState({ DesktopBackLoaded: true })}
            />

        }
    };


    Render_mobile_backgroung() {
        if (this.props.Programs.program_mobile_backgroung) {
            return <img
                style={this.state.MobileBackLoaded ? {} : { opacity: '0' }}
                src={this.props.Programs.program_mobile_backgroung}
                alt=""
                onLoad={() => this.setState({ MobileBackLoaded: true })}
            />

        }
    };


    // SwitchDesktopBack(param) {
    //     if (this.props.Programs.FatchEnd) {
    //         switch (param) {
    //             case 'play-machines-1':
    //                 return toJS(this.props.Programs.Plm1[0].program_desktop_backgroung[0]);
    //             case 'play-machines-2':
    //                 return toJS(this.props.Programs.Plm2[0].program_desktop_backgroung[0]);
    //             default:
    //                 return null;
    //         }
    //     }
    // };


    // SwitchMobileBack(param) {
    //     if (this.props.Programs.FatchEnd) {
    //         switch (param) {
    //             case 'play-machines-1':
    //                 return toJS(this.props.Programs.Plm1[0].program_mobile_backgroung[0]);
    //             case 'play-machines-2':
    //                 return toJS(this.props.Programs.Plm2[0].program_mobile_backgroung[0]);
    //             default:
    //                 return null;
    //         }
    //     }


    // };




    render() {
        const { match } = this.props;
        const ProgramName = match.params.program;
        const ProgramNameNoSpaces = ProgramName.replace(/\s+/g, '');



        let Header = <Navbar GoBack={<FaRegArrowAltCircleLeft />} Home={<FiHome />} />;
        let title = <div className={styles.Title}>Select Program</div>

        isFranchiseOwner() ?
            (
                isAdministrator() ? Header = <NavbarAdmin GoBack={<FaRegArrowAltCircleLeft />} Home={<FiHome />} Title="Lesson Plans" /> : Header = <Navbar Title="Lesson Plans" GoBack={<FaRegArrowAltCircleLeft />} Home={<FiHome />} />
            )
            : (
                Header = <NavbarStudents Title="Select Your Model" GoBack={<FaRegArrowAltCircleLeft />} Home={<FiHome />} />
            )


        isFranchiseOwner() ?
            (
                title = <div className={styles.Title}>Select Program</div>
            )
            : (
                title = <div className={styles.Title}></div>
            )






        return (




            !isLoggedIn() ? (

                window.location.href = '/'

            ) :

                <React.Fragment>

                    {Header}
                    {/* {this.props.Lessons.loading && <img className="loader" src={Loader} alt="Loader" />} */}

                    <div className='PageConatiner'>

                        <div className={styles.ProgramLogo} >
                            {this.RenderLogo()}
                        </div>

                        <Desktop> <div className={styles.BackgroungImg}>
                            {this.Render_desktop_backgroung()}
                        </div> </Desktop>
                        <Mobile>
                            <div className={styles.MobileBackgroungImg}>
                                {this.Render_mobile_backgroung()}
                            </div> </Mobile>

                        {this.props.Lessons.loading &&
                            <div className="ProgramsLoader">
                                <div className={styles.ProgramLogo} >
                                    <img src='https://online.youngengineers.org/db/wp-content/uploads/2020/10/LogoSpace_Empty.png' alt=""></img>

                                </div>
                                <SelectModelContentLoader /> </div>
                        }


                        <SelectModelContent />

                    </div>
                </React.Fragment >

        );


    }
}

export default withRouter(ModelsPage);
