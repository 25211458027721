import clientConfig from '../../../../../../client-config';
const ProgramsUrl = clientConfig.ProgramsUrl;
const FullPath = `${ProgramsUrl}/progrmas/PLAY_MACHINES_1/BuildingInstructions_Images/MainModels`

const Pic1 = `${FullPath}/Seesaw/1_1x.png`;
const Pic2 = `${FullPath}/Seesaw/2_1x.png`;
const Pic3 = `${FullPath}/Seesaw/3_1x.png`;
const Pic4 = `${FullPath}/Seesaw/4_1x.png`;
const Pic5 = `${FullPath}/Seesaw/5_1x.png`;
const Pic6 = `${FullPath}/Seesaw/6_1x.png`;
const Pic7 = `${FullPath}/Seesaw/7_1x.png`;
const Pic8 = `${FullPath}/Seesaw/8_1x.png`;
const Pic9 = `${FullPath}/Seesaw/9_1x.png`;
const Pic10 = `${FullPath}/Seesaw/10_1x.png`;
const Pic11 = `${FullPath}/Seesaw/11_1x.png`;
const Pic12 = `${FullPath}/Seesaw/12_1x.png`;
const Pic13 = `${FullPath}/Seesaw/13_1x.png`;
const Pic14 = `${FullPath}/Seesaw/14_1x.png`;
const Pic15 = `${FullPath}/Seesaw/15_1x.png`;
const Pic16 = `${FullPath}/Seesaw/16_1x.png`;
const Pic17 = `${FullPath}/Seesaw/17_1x.png`;
const Pic18 = `${FullPath}/Seesaw/18_1x.png`;
const Pic19 = `${FullPath}/Seesaw/19_1x.png`;
const Pic20 = `${FullPath}/Seesaw/20_1x.png`;
const Pic21 = `${FullPath}/Seesaw/21_1x.png`;
const Pic22 = `${FullPath}/Seesaw/22_1x.png`;
const Pic23 = `${FullPath}/Seesaw/23_1x.png`;
const Pic24 = `${FullPath}/Seesaw/24_1x.png`;
const Pic25 = `${FullPath}/Seesaw/25_1x.png`;
const Pic26 = `${FullPath}/Seesaw/26_1x.png`;
const Pic27 = `${FullPath}/Seesaw/27_1x.png`;
const Pic28 = `${FullPath}/Seesaw/28_1x.png`;
const Pic29 = `${FullPath}/Seesaw/29_1x.png`;
const Pic30 = `${FullPath}/Seesaw/30_1x.png`;





export const Buildingimages = [

]
    const Pics = [
        Pic1,
        Pic2,
        Pic3,
        Pic4,
        Pic5,
        Pic6,
        Pic7,
        Pic8,
        Pic9,
        Pic10,
        Pic11,
        Pic12,
        Pic13,
        Pic14,
        Pic15,
        Pic16,
        Pic17,
        Pic18,
        Pic19,
        Pic20,
        Pic21,
        Pic22,
        Pic23,
        Pic24,
        Pic25,
        Pic26,
        Pic27,
        Pic28,
        Pic29,
        Pic30,
    ]


    for (let i = 0; i < Pics.length; i++) {
        let obj = {};
        obj['original'] = Pics[i];
        obj['thumbnail'] = Pics[i];
        Buildingimages.push(obj);
    }

export default {
    Buildingimages,
}