import React, { Component } from 'react';

import styles from './BuildingInstructions.module.css';

import { isLoggedIn, isFranchiseOwner, isAdministrator } from "../components/functions";

import NavbarAdmin from "../components/NavbarAdmin2020";
import Navbar from "../components/Navbar";
import NavbarStudents from "../components/NavbarStudents";

import { useMediaQuery } from 'react-responsive';

import Radium from 'radium';

import BackgroungUrl from '../images/WhiteBackground.jpg';
import MobileBackgroungUrl from '../images/WhiteBackgroundMobile.jpg';



const Desktop = ({ children }) => {
	const isDesktop = useMediaQuery({ minWidth: 768 })
	return isDesktop ? children : null
}

const Mobile = ({ children }) => {
	const isDesktop = useMediaQuery({ maxWidth: 767 })
	return isDesktop ? children : null
}


class SignUpNewFranchise extends Component {

	componentDidMount() {

		localStorage.removeItem('valid');

	}

	render() {


		let Header = <Navbar  />;
		let title = <div className={styles.Title}>Select Program</div>

		isFranchiseOwner() ?
			(
				isAdministrator() ? Header = <NavbarAdmin Title="Sign Up New Franchise" /> : Header = <Navbar />
			)
			: (
				Header = <NavbarStudents Title="Select Your Program" />
			)


		isFranchiseOwner() ?
			(
				title = <div className={styles.Title}>Select Program</div>
			)
			: (
				title = <div className={styles.Title}></div>
			)




		return (

			!isLoggedIn() ? (

				window.location.href = '/'

			) :

				<React.Fragment>
					{Header}


					<div className='PageConatiner'>

						<Desktop> <div className={styles.BackgroungImg}><img src={BackgroungUrl} alt="young engineers"></img></div> </Desktop>
						<Mobile> <div className={styles.MobileBackgroungImg}><img src={MobileBackgroungUrl} alt="young engineers"></img></div> </Mobile>

						<div className={styles.Title}>
							<a className={styles.Link} href="https://online.youngengineers.org/db/wp-login.php" target="_blank">
								<button >Wordpress panel</button>
							</a>
						</div>
					</div>
				</React.Fragment>
		);


	}
}

export default Radium(SignUpNewFranchise);
