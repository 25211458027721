import React, { Component } from 'react';
import axios from 'axios';
import clientConfig from '../client-config';
import styles from '../screens/SignUpNewStudent.module.css';
import { Link } from "react-router-dom";

import * as EmailValidator from 'email-validator';
import DatePicker from "react-datepicker";
import { getUserName } from "../components/functions";
import "react-datepicker/dist/react-datepicker.css";

import Loader from "../loader.gif";
import BackgroungUrl from '../images/WhiteBackground.jpg';
import MobileBackgroungUrl from '../images/WhiteBackground.jpg';
import userIcon from '../images/user_icon.png';





class FormSignUpNewStudent extends Component {


	constructor(props) {
		super(props);

		this.state = {
			ParentName: '',
			StudentFirstName: '',
			StudentLastName: '',
			ParentEmail: '',
			Phone: '',
			Program: '',
			LessonStartDate: '',
			LessonEndDate: '',
			NumberOfLessons: '',
			loading: false,
			error: '',
			errorMessage: '',
			showForm: true,
		}
	}



	ValidateForm = (event) => {
		event.preventDefault();
		const { ParentName, StudentFirstName, StudentLastName, ParentEmail, Phone, Program, LessonStartDate, LessonEndDate, NumberOfLessons } = this.state;

		this.setState({ errorMessage: '' });

		if (StudentFirstName === '') {
			this.setState({ errorMessage: <div className="FormError">Enter Student First Name</div> });
			return false;
		}

		if (StudentLastName === '') {
			this.setState({ errorMessage: <div className="FormError">Enter Student Last Name</div> });
			return false;
		}

		if (ParentName === '') {
			this.setState({ errorMessage: <div className="FormError">Enter Parent Name</div> });
			return false;
		}

		if (ParentEmail === '') {
			this.setState({ errorMessage: <div className="FormError">Enter Parent Email</div> });
			return false;
		}

		if (!EmailValidator.validate(ParentEmail)) {
			this.setState({ errorMessage: <div className="FormError">Enter Vaild Eail</div> });
			return false;
		}

		if (Phone === '') {
			this.setState({ errorMessage: <div className="FormError">Enter Phone</div> });
			return false;
		}

		if (Program === '') {
			this.setState({ errorMessage: <div className="FormError">Select Program</div> });
			return false;
		}

		if (LessonStartDate === '') {
			this.setState({ errorMessage: <div className="FormError">Enter Lesson Start Date</div> });
			return false;
		}
		if (LessonEndDate === '') {
			this.setState({ errorMessage: <div className="FormError">Enter Lesson End Date</div> });
			return false;
		}
		if (NumberOfLessons === '') {
			this.setState({ errorMessage: <div className="FormError">Enter Number Of Lessons</div> });
			return false;
		}
		this.handleFormSubmit()

	};


	ChangeState = (event) => {

		this.setState({
			showForm: true,
			ParentName: '',
			StudentFirstName: '',
			StudentLastName: '',
			ParentEmail: '',
			Phone: '',
			Program: '',
			LessonStartDate: '',
			LessonEndDate: '',
			NumberOfLessons: '',
			loading: false,
			error: '',
			errorMessage: '',
		});
	};

	handleOnChange = (event) => {
		this.setState({ [event.target.name]: event.target.value });
	};


	handleStartDateChange = date => {
		this.setState({
			LessonStartDate: date

		});
	};

	handleEndDateChange = date => {
		this.setState({
			LessonEndDate: date

		});
	};



	handleFormSubmit = () => {
		// event.preventDefault();

		const wordPressSiteUrl = clientConfig.siteUrl;
		const AUTH_KEY = clientConfig.AUTH_KEY;
		const UserId = localStorage.getItem('userId')

		const { ParentName, StudentFirstName, StudentLastName, ParentEmail, Phone, Program, LessonStartDate, LessonEndDate, NumberOfLessons, loading } = this.state;

		let data_to_post = {}
		data_to_post.AUTH_KEY = AUTH_KEY
		data_to_post.ParentName = ParentName
		data_to_post.StudentFirstName = StudentFirstName
		data_to_post.StudentLastName = StudentLastName
		data_to_post.ParentEmail = ParentEmail
		data_to_post.Phone = Phone
		data_to_post.Program = Program
		data_to_post.LessonStartDate = LessonStartDate
		data_to_post.LessonEndDate = LessonEndDate
		data_to_post.NumberOfLessons = NumberOfLessons
		data_to_post.TeacherId = UserId

		this.setState({ loading: true }, () => {

			axios.post(`${wordPressSiteUrl}/wp-json/wl/v1/create_student`, data_to_post, {
				headers: {
					'Content-Type': 'application/json',
				}
			})
				.then(res => {
					console.log(res.data)
					this.setState({ loading: false });
					if (res.data === 'already_exists') {
						this.setState({ errorMessage: <div className="FormError">Email already exists. Please try another one.</div> });
					}

					if (res.data === 'email_sent') {
						this.setState({ showForm: false });
					}
				})
				.catch(err => {
					this.setState({ loading: false });
				})
		})

	};

	render() {

		const smallDevice = window.matchMedia('(max-width: 400px)').matches;


		const { ParentName, StudentFirstName, StudentLastName, ParentEmail, Phone, Program, LessonStartDate, LessonEndDate, NumberOfLessons, loading } = this.state;

		const loggedInUserEmail = localStorage.getItem('user_email');

		const BackgroundStyle = {
			backgroundImage: 'url(' + BackgroungUrl + ')',

			//On moble screen
			'@media (maxWidth: 801px)': {
				backgroundImage: 'url(' + MobileBackgroungUrl + ')',
			}

		};


		let pageContent = "";
		const userName = (getUserName()) ? getUserName() : '';

		if (this.state.showForm) {
			pageContent = <form className={styles.FormContainer} onSubmit={this.ValidateForm}>
				<div style={{ width: '100%' }}>

					<div className={styles.FormTitle}>Sign Up New Student</div>
					{this.state.errorMessage}



					<div className={styles.form_item}>
						<input
							placeholder="Student First Name"
							type="text"
							className={styles.FromInput}
							name="StudentFirstName"
							value={StudentFirstName}
							onChange={this.handleOnChange}
						/>
					</div>

					<div className={styles.form_item}>
						<input
							placeholder="Student Last Name"
							type="text"
							className={styles.FromInput}
							name="StudentLastName"
							value={StudentLastName}
							onChange={this.handleOnChange}
						/>
					</div>


					<div className={styles.form_item}>
						<input
							placeholder="Parent Name"
							type="text"
							className={styles.FromInput}
							name="ParentName"
							value={ParentName}
							onChange={this.handleOnChange}
						/>
					</div>

					<div className={styles.form_item}>
						<input
							placeholder="Username (email)"
							type="text"
							className={styles.FromInput}
							name="ParentEmail"
							value={ParentEmail}
							onChange={this.handleOnChange}
						/>
					</div>

					<div className={styles.form_item}>
						<input
							placeholder="Phone"
							type="text"
							className={styles.FromInput}
							name="Phone"
							value={Phone}
							onChange={this.handleOnChange}
						/>
					</div>

					<div className={styles.form_item}>
						<select id="Program" onChange={this.handleOnChange} name="Program" value={Program} >
							<option value="" className={styles.SelectPlaceholder}>Select Program</option>
							<option value="Play Machines 1">Play Machines 1</option>
							<option value="Play Machines 2">Play Machines 2</option>
							<option value="Bricks Challenge 1">Bricks Challenge 1</option>
							<option value="Bricks Challenge 2">Bricks Challenge 2</option>
							<option value="Galileo Technic 1">Galileo Technic 1</option>
							<option value="Galileo Technic 2">Galileo Technic 2</option>
							<option value="AlgoPlay 1">AlgoPlay 1</option>
							<option value="AlgoPlay 2">AlgoPlay 2</option>
							<option value="Robobricks 1">Robobricks 1</option>
							<option value="Robobricks 2">Robobricks 2</option>
							<option value="Robotics 1">Robotics 1</option>
							<option value="Robotics 2">Robotics 2</option>
							<option value="Big Builders 1">Big Builders 1</option>
							<option value="Big Builders 2">Big Builders 2</option>
						</select>
					</div>

					<div className={styles.Dateform_item}>
						<div className={styles.Dateform_FlexContainer}>
							<div className={styles.DateformTitle}>Lessons Start date</div>
							<div className={styles.DateformDatefiled}>
								<DatePicker
									withPortal={smallDevice}
									selected={LessonStartDate}
									onChange={this.handleStartDateChange}
								/>
							</div>
						</div>
					</div>


					<div className={styles.Dateform_item}>
						<div className={styles.Dateform_FlexContainer}>
							<div className={styles.DateformTitle}>Lessons End date</div>
							<div className={styles.DateformDatefiled}>
								<DatePicker
									withPortal={smallDevice}
									selected={LessonEndDate}
									onChange={this.handleEndDateChange}
								/>
							</div>
						</div>
					</div>



					<div className={styles.Dateform_item}>
						<div className={styles.Dateform_FlexContainer}>
							<div className={styles.DateformTitle}>Number of lessons</div>
							<div className={styles.DateformDatefiled}>
								<input
									type="number"
									className={styles.FromInput}
									name="NumberOfLessons"
									value={NumberOfLessons}
									onChange={this.handleOnChange}
								/>
							</div>
						</div>
					</div>


					<div className={'Submitbutton ' + styles.Submitbutton}>
						<button className="btn" type="submit">Add Student</button>
					</div>
				</div>
				<div>
					<p className={styles.LoginSmallText}>User name and password will be sent to parent email
			<br />
						<span className={styles.Space}>&nbsp;</span>and to your email: {loggedInUserEmail}</p>


				</div>



				{loading && <img className="loader" src={Loader} alt="Loader" />}
			</form>
		}
		else {
			pageContent = <div className={styles.FormContainer} onSubmit={this.ValidateForm}>
				<div style={{ width: '100%' }}>

					<div className={styles.ConfirmTitle}>New Student Added <br /> successfully</div>
					<div className={styles.Link}>
						<Link to={`/students_list/${userName}`}>Students List</Link>
					</div>

					<div className={styles.AddStudent} onClick={this.ChangeState}>
						<img src={userIcon} alt="userIcon"></img>
						<div className={styles.AddStudentText}>add More Student</div>
					</div>
				</div>
			</div>


		}

		return (


			<React.Fragment>
				{pageContent}
			</React.Fragment>
		);


	}
}

export default FormSignUpNewStudent;
