import React, { Component } from 'react';
import axios from 'axios';
import clientConfig from '../client-config';
import styles from './LoginScreen.module.css';
import Loader from "../loader.gif";

import Checkbox from 'rc-checkbox';

import Radium from 'radium';
import { Redirect, Link } from 'react-router-dom'


import BackgroungUrl from '../images/LoginBackground.jpg';
import MobileBackgroungUrl from '../images/LoginBackgroundMobile.jpg';
import young_engineers_logo from '../images/young_engineers_logo.png';
import online_logo from '../images/online_logo.png';
import ModelItem from './fix2020/Modelitem';
import Lesson from './fix2020/Lesson';

require('rc-checkbox/assets/index.css');
class LoginScreen extends Component {

  constructor(props) {
    super(props);

    this.state = {
      username: '',
      password: '',
      userNiceName: '',
      userEmail: '',
      userId: '',
      userRoles: '',
      user_display_name: '',
      loggedIn: false,
      loading: false,
      error: '',
      errorMessage: '',
      CheckboxDisabled: false,
      Termofuse: false,
    }
  }

  handleOnChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };


 HandleKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.ValidateForm()
		}
  }

 

  handleTermofuse = (e) => {
    this.setState({ Termofuse: e.target.checked })
    // console.log(this.state.Termofuse);
  }


  ValidateForm = () => {
    // console.log(this.state.Termofuse)


    if (this.state.username === '') {
      this.setState({ errorMessage: <div className="FormError">Enter an email or username</div> });
      this.username.focus();
      return false
    }

    if (this.state.password === '') {
      this.setState({ errorMessage: <div className="FormError">Enter password</div> });
      this.password.focus();
      return false
    }

    if (!this.state.Termofuse) {
      this.setState({ errorMessage: <div className="FormError">you must agree to the terms of use</div> });
      this.Termofuse.focus();
      return false
    }

    if (this.state.error) {
      this.setState({ errorMessage: <div className="FormError">Wrong username or password</div> });
      return false
    }
    else return this.FormSubmit()

  }


  FormSubmit = (event) => {

    // event.preventDefault();

    const siteUrl = clientConfig.siteUrl;

    const loginData = {
      username: this.state.username,
      password: this.state.password,
    };

    this.setState({ loading: true }, () => {

      axios.post(`${siteUrl}/wp-json/jwt-auth/v1/token`, loginData)
        .then(res => {
          // console.log(res)
          if (undefined === res.data.token) {

            this.setState({ error: res.data.message, loading: false });
            return;
          }

          const { token, user_nicename, user_email, user_id, user_roles, user_display_name } = res.data;

          localStorage.setItem('token', token);
          localStorage.setItem('userName', user_display_name);
          localStorage.setItem('userId', user_id);
          localStorage.setItem('userRoles', user_roles[0]);
          localStorage.setItem('user_email', user_email);

          // see on ModelItem.js and Lesson.js
          const OpenedPrograms = [];
          localStorage.setItem("OpenedPrograms", JSON.stringify(OpenedPrograms)); //store OpenedPrograms



          this.setState({
            loading: false,
            token: token,
            userNiceName: user_nicename,
            userEmail: user_email,
            userId: user_id,
            userRoles: user_roles[0],
            loggedIn: true
          })
        })
        .catch(err => {
          this.setState({ loading: false });
          this.setState({ errorMessage: <div className="FormError">Wrong username or password</div> });
        })
    })



  };



  render() {

    const { username, password, loggedIn, loading, user_display_name } = this.state;

    const user = (user_display_name) ? user_display_name : localStorage.getItem('userName');



    const BackgroundStyle = {
      backgroundImage: 'url(' + BackgroungUrl + ')',

      //On moble screen
      '@media (max-width: 801px)': {
        backgroundImage: 'url(' + MobileBackgroungUrl + ')',
      }

    };



    if (loggedIn || localStorage.getItem('token')) {


      const userRoles = localStorage.getItem('userRoles');

      switch (userRoles) {
        case 'administrator':
          return (<Redirect push to={`/admin/Franchise_Owners_List`} />)
        case 'franchise_owner':
          return (<Redirect push to={`/students_list/${user}`} />)
        default:
          return (<Redirect push to={`/building_instructions/${user}`} />)
      }
    } else {
      return (
        <div style={BackgroundStyle} className={styles.LoginConatiner + ' BackgroundContainer'}>

          <div className={styles.FormContainer}>
            <div style={{ width: '100%' }}>
              <div className={styles.LogosContainer}>
                <img className={styles.YeLogo} src={young_engineers_logo} alt="young engineers logo"></img>
                <img className={styles.OnlineLogo} src={online_logo} alt="young engineers online logo"></img>
              </div>
              <div className="FormTitle">Log in</div>

              {this.state.errorMessage}

              <div className="form_item">
                <input
                  placeholder="Username or Email"
                  type="text"
                  className="form-input"
                  name="username"
                  value={username}
                  onChange={this.handleOnChange}
                  onKeyPress={this.HandleKeyPress}
                  ref={input => { this.username = input; }}
                />
              </div>

              <div className="form_item with_comment">
                <input
                  placeholder="Password"
                  type="password"
                  className="form-input"
                  name="password"
                  value={password}
                  onChange={this.handleOnChange}
                  onKeyPress={this.HandleKeyPress}
                  ref={input => { this.password = input; }}
                />
              </div>
              <div className="FlexContainer">
                <div className={styles.InputComment}>
                  <div className={styles.ForgotPassword}> <Link to="/PasswordReset">Forgot password?</Link></div>

                  <div className={styles.byLogintext}>
                    <Checkbox
                      // defaultChecked
                      onChange={this.handleTermofuse}
                      disabled={this.state.CheckboxDisabled}
                      ref={input => { this.Termofuse = input; }}
                    />

                    <span className={styles.byLogintextSpan}>By logging in I approve the </span><Link className={styles.TermBold} to="/terms_of_use">Terms Of Use</Link></div>
                </div>
              </div>


              <div className="form_item Submitbutton">

                <button className="btn" type="submit" onClick={this.ValidateForm}>Login</button>
              </div>
            </div>
            <div>
              <p className={styles.LoginSmallText}>Want to join Young Engineers?</p>

              <p className={styles.LoginSmallText}>
                Student <a href="https://youngengineers.org/">Learn More</a>
                <span className="Space">|</span>
              Teacher<a className="Space" href="https://youngengineers.org/">Learn More</a></p>
            </div>

            {loading && <img className="loader" src={Loader} alt="Loader" />}
          </div>

        </div>

      );


    }
  }
}

export default Radium(LoginScreen);
