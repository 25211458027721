import React, { Component } from 'react';
import styles from './BuildingInstructions.module.css';
import { isLoggedIn, isFranchiseOwner, isAdministrator } from "../components/functions";

import NavbarAdmin from "../components/NavbarAdmin2020";
import Navbar from "../components/NavbarFranchise";
import NavbarStudents from "../components/NavbarStudents";

import { useMediaQuery } from 'react-responsive';

import Radium from 'radium';

import SelectProgramGrid from '../components/SelectProgramGrid'

import BackgroungUrl from '../images/select_program.jpg';
import MobileBackgroungUrl from '../images/select_programMobile.jpg';



const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 768 })
    return isDesktop ? children : null
}

const Mobile = ({ children }) => {
    const isDesktop = useMediaQuery({ maxWidth: 767 })
    return isDesktop ? children : null
}


class BuildingInstructions extends Component {

    componentDidMount() {

        localStorage.removeItem('valid');

    }

    render() {


        let Header = <Navbar />;
        let title = <div className={styles.Title}>Select Program</div>

        isFranchiseOwner() ?
            (
                isAdministrator() ? Header = <NavbarAdmin Title="Select Program" /> : Header = <Navbar Title="Select Program" />
            )
            : (
                Header = <NavbarStudents Title="Select Your Program" />
            )


        isFranchiseOwner() ?
            (
                title = <div className={styles.Title}></div>
            )
            : (
                title = <div className={styles.Title}></div>
            )




        return (

            !isLoggedIn() ? (

                window.location.href = '/'

            ) :

                <React.Fragment>
                    {Header}


                    <div className='PageConatiner'>
                        {title}

                        <Desktop> <div className={styles.BackgroungImg}><img src={BackgroungUrl} alt="young engineers"></img></div> </Desktop>
                        <Mobile> <div className={styles.MobileBackgroungImg}><img src={MobileBackgroungUrl} alt="young engineers"></img></div> </Mobile>
                        <SelectProgramGrid />

                    </div>
                   
                </React.Fragment>
        );


    }
}

export default Radium(BuildingInstructions);
