import clientConfig from '../../../../../../client-config';
const ProgramsUrl = clientConfig.ProgramsUrl;
const FullPath = `${ProgramsUrl}/progrmas/PLAY_MACHINES_1/BuildingInstructions_Images/MainModels`

const Pic1 = `${FullPath}/GearboxRideControl/1_1x.png`;
const Pic2 = `${FullPath}/GearboxRideControl/2_1x.png`;
const Pic3 = `${FullPath}/GearboxRideControl/3_1x.png`;
const Pic4 = `${FullPath}/GearboxRideControl/4_1x.png`;
const Pic5 = `${FullPath}/GearboxRideControl/5_1x.png`;
const Pic6 = `${FullPath}/GearboxRideControl/6_1x.png`;
const Pic7 = `${FullPath}/GearboxRideControl/7_1x.png`;
const Pic8 = `${FullPath}/GearboxRideControl/8_1x.png`;
const Pic9 = `${FullPath}/GearboxRideControl/9_1x.png`;
const Pic10 = `${FullPath}/GearboxRideControl/10_1x.png`;
const Pic11 = `${FullPath}/GearboxRideControl/11_1x.png`;
const Pic12 = `${FullPath}/GearboxRideControl/12_1x.png`;
const Pic13 = `${FullPath}/GearboxRideControl/13_1x.png`;
const Pic14 = `${FullPath}/GearboxRideControl/14_1x.png`;
const Pic15 = `${FullPath}/GearboxRideControl/15_1x.png`;
const Pic16 = `${FullPath}/GearboxRideControl/16_1x.png`;
const Pic17 = `${FullPath}/GearboxRideControl/17_1x.png`;
const Pic18 = `${FullPath}/GearboxRideControl/18_1x.png`;
const Pic19 = `${FullPath}/GearboxRideControl/19_1x.png`;
const Pic20 = `${FullPath}/GearboxRideControl/20_1x.png`;
const Pic21 = `${FullPath}/GearboxRideControl/21_1x.png`;
const Pic22 = `${FullPath}/GearboxRideControl/22_1x.png`;
const Pic23 = `${FullPath}/GearboxRideControl/23_1x.png`;
const Pic24 = `${FullPath}/GearboxRideControl/24_1x.png`;
const Pic25 = `${FullPath}/GearboxRideControl/25_1x.png`;
const Pic26 = `${FullPath}/GearboxRideControl/26_1x.png`;
const Pic27 = `${FullPath}/GearboxRideControl/27_1x.png`;
const Pic28 = `${FullPath}/GearboxRideControl/28_1x.png`;
const Pic29 = `${FullPath}/GearboxRideControl/29_1x.png`;
const Pic30 = `${FullPath}/GearboxRideControl/30_1x.png`;
const Pic31 = `${FullPath}/GearboxRideControl/31_1x.png`;
const Pic32 = `${FullPath}/GearboxRideControl/32_1x.png`;
const Pic33 = `${FullPath}/GearboxRideControl/33_1x.png`;
const Pic34 = `${FullPath}/GearboxRideControl/34_1x.png`;
const Pic35 = `${FullPath}/GearboxRideControl/35_1x.png`;
const Pic36 = `${FullPath}/GearboxRideControl/36_1x.png`;
const Pic37 = `${FullPath}/GearboxRideControl/37_1x.png`;
const Pic38 = `${FullPath}/GearboxRideControl/38_1x.png`;





export const Buildingimages = [

]
    const Pics = [
        Pic1,
        Pic2,
        Pic3,
        Pic4,
        Pic5,
        Pic6,
        Pic7,
        Pic8,
        Pic9,
        Pic10,
        Pic11,
        Pic12,
        Pic13,
        Pic14,
        Pic15,
        Pic16,
        Pic17,
        Pic18,
        Pic19,
        Pic20,
        Pic21,
        Pic22,
        Pic23,
        Pic24,
        Pic25,
        Pic26,
        Pic27,
        Pic28,
        Pic29,
        Pic30,
        Pic31,
        Pic32,
        Pic33,
        Pic34,
        Pic35,
        Pic36,
        Pic37,
        Pic38,
    ]


    for (let i = 0; i < Pics.length; i++) {
        let obj = {};
        obj['original'] = Pics[i];
        obj['thumbnail'] = Pics[i];
        Buildingimages.push(obj);
    }

export default {
    Buildingimages,
}