import { observable, configure, runInAction, action, autorun, computed, toJS } from 'mobx';

import { computedFn } from 'mobx-utils';

import axios from 'axios';
import clientConfig from '../client-config';

configure({ enforceActions: true });


class UsersList {
    @observable AllUsers = []
    @observable FilterdUserName = []
    @observable AllStudents = []
    @observable loading = true



    // FILTER ONLY STUDENTS (mobx-utils)
    getAllStudents = computedFn(function getAllStudents(SortBy) {
        const GetSortBy = SortBy //Must put arguments (SortBy) to new const
        let HowToSort

        switch (GetSortBy) {

            case "student_first_nameA":
             
                HowToSort = this.AllStudents.sort((a, b) => a.student_first_name.localeCompare(b.student_first_name))
                return HowToSort;

            case "student_first_nameZ":
                HowToSort = this.AllStudents.sort((a, b) => b.student_first_name.localeCompare(a.student_first_name))
                return HowToSort

            case "student_last_nameA":
                HowToSort = this.AllStudents.sort((a, b) => a.student_last_name.localeCompare(b.student_last_name))
                return HowToSort;

            case "student_last_nameZ":
                HowToSort = this.AllStudents.sort((a, b) => b.student_last_name.localeCompare(a.student_last_name))
                return HowToSort

            case "parent_nameA":
                HowToSort = this.AllStudents.sort((a, b) => a.parent_name.localeCompare(b.parent_name))
                return HowToSort;

            case "parent_nameZ":
                HowToSort = this.AllStudents.sort((a, b) => b.parent_name.localeCompare(a.parent_name))
                return HowToSort;

            case "programA":
                HowToSort = this.AllStudents.sort((a, b) => a.program.localeCompare(b.program))
                return HowToSort;

            case "programZ":
                HowToSort = this.AllStudents.sort((a, b) => b.program.localeCompare(a.program))
                return HowToSort;

            case "lessons_start_dateA":
                HowToSort = this.AllStudents.sort((a, b) => a.lessons_start_date.localeCompare(b.lessons_start_date))
                return HowToSort;

            case "lessons_start_dateZ":
                HowToSort = this.AllStudents.sort((a, b) => b.lessons_start_date.localeCompare(a.lessons_start_date))
                return HowToSort;

            case "lessons_end_dateA":
                HowToSort = this.AllStudents.sort((a, b) => a.lessons_end_date.localeCompare(b.lessons_end_date))
                return HowToSort;

            case "lessons_end_dateZ":
                HowToSort = this.AllStudents.sort((a, b) => b.lessons_end_date.localeCompare(a.lessons_end_date))
                return HowToSort;

            case "number_of_lessonsUp":
                HowToSort = this.AllStudents.sort((a, b) => a.number_of_lessons - b.number_of_lessons)
                return HowToSort;

            case "number_of_lessonsDown":
                HowToSort = this.AllStudents.sort((a, b) => b.number_of_lessons - a.number_of_lessons)
                return HowToSort;

    

            default:
                HowToSort = this.AllStudents.sort((a, b) => a.student_first_name.localeCompare(b.student_first_name))
                return HowToSort
        }



    })






    // getUserById = computedFn(function getUserById(getUserById) {
    //     const GetId = Id//Must put atguments (SortBy) to new const



    //     this.GetName(GetId)

    //     return this.GetName()
    // })


    // GetName(GetId) {
    //     let Students
    //     Students = this.AllUsers.filter(student => student.user_id === 'simcha')
    //     console.log(Students)
    //     return Students.FirstName
    // }

    // FIRST LOAD OF ALL USERS
    constructor() {
        this.loadUsers()
    }

    loadUsers() {
        const wordPressSiteUrl = clientConfig.siteUrl;
        const AUTH_KEY = clientConfig.AUTH_KEY;
        let data_to_post = {}
        data_to_post.AUTH_KEY = AUTH_KEY

        axios.post(`${wordPressSiteUrl}/wp-json/wl/v1/get_all_users_data`, data_to_post, {
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(res => {




                // const Filterd = res.data.filter(student => student.role === 'student')

                // console.log(Franchise)

                // Country: "jk"
                // Email: "sim444cha@activebranding.co.il"
                // FirstName: "simcha"
                // LastName: "54445"
                // RegisteredDate: "2020-05-04 15:51:55"
                // State: "hjkj"
                // Territory: "kjllk"
                // lessons_end_date: null
                // lessons_start_date: null
                // number_of_lessons: null
                // parent_email: null
                // parent_name: null
                // phone: null
                // program: null
                // role: "franchise_owner"
                // student_first_name: null
                // student_last_name: null
                // teacher_id: null

                // for (let i = 0; i < Filterd.length; i++) {
                //     let obj = [];

                //     obj = Franchise.filter(student => student.teacher_id === JSON.stringify(FranchiseOwner[i].user_id))

                //     Object.assign(Filterd[i], { TeacherNaem: JSON.stringify(obj.length) });
                //     // console.log(FranchiseOwner)
                // }



                runInAction(() => {
                    const AllUsers = res.data
                    let Students = res.data.filter(student => student.role === 'student')

                    for (let i = 0; i < Students.length; i++) {
                        const TeacherId = Students[i].teacher_id
                        const Franchise = res.data.filter(user => user.role === 'franchise_owner')
                        const GetTeacher = Franchise.filter(user => user.user_id.toString() === TeacherId.toString())

                        // Students[i].push(obj)
                        Object.assign(Students[i], { TeacherData: GetTeacher });
                        // console.log(Students)
                        // Students[i].push(GetTeacher)
                    }


                    // obj['original'] = Pics[i];
                    // obj['thumbnail'] = Pics[i];
                    // Buildingimages.push(obj);

                    Object.assign(this.AllUsers, AllUsers)
                    Object.assign(this.FilterdUserName, AllUsers)
                    Object.assign(this.AllStudents, Students)
                    // console.log(this.AllStudents[0].TeacherData[0].FirstName)
                    this.loading = false
                });
            })
            .catch(err => {

            })
    }


}

export default new UsersList();





