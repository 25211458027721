import React, { Component } from 'react';

import styles from '../../screens/BuildingInstructions.module.css';
import { withRouter } from 'react-router-dom';

import { isLoggedIn, isFranchiseOwner, isAdministrator } from "../../components/functions";

import NavbarAdmin from "../../components/NavbarAdmin";
import Navbar from "../../components/Navbar";
import NavbarStudents from "../../components/NavbarStudents";

import { useMediaQuery } from 'react-responsive';
import PropTypes from "prop-types";

import BackgroungUrl from '../../images/PLAY_MACHINES_1.jpg';
import MobileBackgroungUrl from '../../images/PLAY_MACHINES_1_Mobile.jpg';

import PlayMachines1Models from '../SingleProgram/PLAY_MACHINES_1/AllSingleModelsContent'
import PlayMachines2Models from '../SingleProgram/PLAY_MACHINES_2/AllSingleModelsContent'




const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 801 })
    return isDesktop ? children : null
}

const Mobile = ({ children }) => {
    const isDesktop = useMediaQuery({ maxWidth: 800 })
    return isDesktop ? children : null
}


class SingleModelPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            Program: '',
        }
    }
    onDocumentLoadSuccess = ({ numPages }) => {
        this.setState({ numPages });
    }

    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired
    };


    componentDidMount() {
        this.setState({ Program: this.props.programName });
    }

    render() {
        const { match } = this.props;
        let Header = <Navbar />;
        let title = <div className={styles.Title}>{this.props.programName}</div>

        isFranchiseOwner() ?
            (
                isAdministrator() ? Header = <NavbarAdmin /> : Header = <Navbar />
            )
            : (
                Header = <NavbarStudents Title={match.params.ModelName} GoBack="<< Go Back" />
            )

        isFranchiseOwner() ?
            (

                title = <div className={styles.Title}>{match.params.ModelName}</div>
            )
            : (
                title = <div className={styles.Title}></div>
            )

        let GoBack
        isFranchiseOwner() ?
            (

                GoBack = <div onClick={() => this.props.history.goBack()} className={styles.GoBack}>{"<< "}Go Back</div>
            )
            : (
                GoBack = null
            )

        const modelName = match.params.ModelName;
        const modelNameNoSpaces = modelName.replace(/\s+/g, '');
        const IsInLocalStorage = localStorage.getItem(modelNameNoSpaces);

        const CanSeeModel = () => {
            if (isFranchiseOwner()) {
                return true
            }
            if (IsInLocalStorage) {
                return true
            }
            else if (!isLoggedIn()) {
                return false
            }
            else {
                return false
            }
        }



        const programName = match.params.programName;

        const programNameNoSpace = programName.replace(/\s+/g, '');

        const ProgramComponent = () => {
            switch (programNameNoSpace) {
                case 'PlayMachines1':
                    return <PlayMachines1Models ModelNameNoSpaces={modelNameNoSpaces} />;
                case 'PlayMachines2':
                    return <PlayMachines2Models ModelNameNoSpaces={modelNameNoSpaces} />;
                default:
                    return null;
            }
        }


        return (

            !CanSeeModel() ? (

                window.location.href = '/'

            ) :

                <React.Fragment>



                    <div className='PageConatiner'>
                        {Header}
                        {title}
                        {GoBack}
                        <Desktop> <div className={styles.BackgroungImg}><img src={BackgroungUrl} alt="young engineers logo"></img></div> </Desktop>
                        <Mobile> <div className={styles.MobileBackgroungImg}><img src={MobileBackgroungUrl} alt="young engineers logo"></img></div> </Mobile>

                        <div className={styles.ImageGalleryContainer}>
                            {
                                ProgramComponent()
                            }
                        </div>

                    </div>
                </React.Fragment>
        );


    }
}

export default withRouter(SingleModelPage);
