import React from 'react';
import styles from '../../../screens/SingleProgram/Programs.module.css';
import Modelitem from '../Modelitem';
import { getUserName } from "../../../components/functions";

import clientConfig from '../../../client-config';
const ProgramsUrl = clientConfig.ProgramsUrl;
const FullPath = `${ProgramsUrl}/progrmas/PLAY_MACHINES_1/SmallPics/ExplorationModels`

const CentrifugalForce = `${FullPath}/CentrifugalForce.png`;
const DoubleSpeedIncreasingTransmission = `${FullPath}/DoubleSpeedIncreasingTransmission.png`;
const DoubleSpeedDecreasingTransmission = `${FullPath}/DoubleSpeedReductionTransmission.png`;
const FastAndSlowTransmission = `${FullPath}/FastAndSlowTransmission.png`;
const Gearbox = `${FullPath}/Gearbox.png`;
const MechanicalCam = `${FullPath}/MechanicalCam.png`;
const PerimeterRollingAndSliding = `${FullPath}/PerimeterRollingAndSliding.png`;
const SpeedIncreasingTransmission = `${FullPath}/SpeedIncreadingTransmission8gear.png`;
const SimpleGearTransmission = `${FullPath}/SimpleGearTransmission.png`;



class ExperimentInstructionsTabContent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ManuIsOpen: false,
        }
    }

    render() {
        const userName = (getUserName()) ? getUserName() : '';
        const programName = 'PlayMachines1';

        return (
            <div className={styles.FlexContainer}>



                <div className={styles.ItemsContainer}>

                    {/* ITEM TO LOOP FROM DATA BASE */}
                    <Modelitem
                        Title="Centrifugal Force"
                        TopPosition='0px'
                        ModelPic={CentrifugalForce}
                        Password='4567'
                        userName={userName}
                        programName={programName}
                        TitleBackColor='gray'
                        localStorageItemName='CentrifugalForce'
                    />
                    {/* ITEM TO LOOP FROM DATA BASE */}


                    {/* ITEM TO LOOP FROM DATA BASE */}
                    <Modelitem
                        Title="Double Speed Increasing Transmission"
                        TopPosition='0px'
                        ModelPic={DoubleSpeedIncreasingTransmission}
                        Password='7785'
                        userName={userName}
                        programName={programName}
                        TitleBackColor='gray'
                        localStorageItemName='DoubleSpeedIncreasingTransmission'
                    />
                    {/* ITEM TO LOOP FROM DATA BASE */}

                    {/* ITEM TO LOOP FROM DATA BASE */}
                    <Modelitem
                        Title="Double Speed Decreasing Transmission"
                        TopPosition='0px'
                        ModelPic={DoubleSpeedDecreasingTransmission}
                        Password='7821'
                        userName={userName}
                        programName={programName}
                        TitleBackColor='gray'
                        localStorageItemName='DoubleSpeedDecreasingTransmission'
                        TitleclassName={styles.ExtraWideTitle}
                    />
                    {/* ITEM TO LOOP FROM DATA BASE */}

                    {/* ITEM TO LOOP FROM DATA BASE */}
                    <Modelitem
                        Title="Fast and Slow Transmission "
                        TopPosition='0px'
                        ModelPic={FastAndSlowTransmission}
                        Password='1358'
                        userName={userName}
                        programName={programName}
                        TitleBackColor='gray'
                        localStorageItemName='FastandSlowTransmission'
                    />
                    {/* ITEM TO LOOP FROM DATA BASE */}

                    {/* ITEM TO LOOP FROM DATA BASE */}
                    <Modelitem
                        Title="Gearbox"
                        TopPosition='0px'
                        ModelPic={Gearbox}
                        Password='2456'
                        userName={userName}
                        programName={programName}
                        TitleBackColor='gray'
                        localStorageItemName='Gearbox'
                    />
                    {/* ITEM TO LOOP FROM DATA BASE */}



                    {/* ITEM TO LOOP FROM DATA BASE */}
                    <Modelitem
                        Title="Mechanical Cam"
                        TopPosition='0px'
                        ModelPic={MechanicalCam}
                        Password='9668'
                        userName={userName}
                        programName={programName}
                        TitleBackColor='gray'
                        localStorageItemName='MechanicalCam'
                    />
                    {/* ITEM TO LOOP FROM DATA BASE */}

                    {/* ITEM TO LOOP FROM DATA BASE */}
                    <Modelitem
                        Title="Perimeter, Rolling And Sliding"
                        TopPosition='0px'
                        ModelPic={PerimeterRollingAndSliding}
                        Password='0334'
                        userName={userName}
                        programName={programName}
                        TitleBackColor='gray'
                        localStorageItemName='Perimeter,RollingAndSliding'
                    />
                    {/* ITEM TO LOOP FROM DATA BASE */}

                    {/* ITEM TO LOOP FROM DATA BASE */}
                    <Modelitem
                        Title="Speed Increasing Transmission"
                        TopPosition='0px'
                        ModelPic={SpeedIncreasingTransmission}
                        Password='4703'
                        userName={userName}
                        programName={programName}
                        TitleBackColor='gray'
                        localStorageItemName='SpeedIncreasingTransmission'
                    />
                    {/* ITEM TO LOOP FROM DATA BASE */}


                    {/* ITEM TO LOOP FROM DATA BASE */}
                    <Modelitem
                        Title="Simple Gear Transmission"
                        TopPosition='0px'
                        ModelPic={SimpleGearTransmission}
                        Password='7636'
                        userName={userName}
                        programName={programName}
                        TitleBackColor='gray'
                        localStorageItemName='SimpleGearTransmission'
                    />
                    {/* ITEM TO LOOP FROM DATA BASE */}





                </div>


            </div>
        );
    }
}

export default ExperimentInstructionsTabContent;