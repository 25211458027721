import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { Provider } from 'mobx-react'

import { BrowserRouter } from "react-router-dom";
import stores from './stores';

ReactDOM.render(
  <Provider usersList={stores.usersList} pages={stores.pages} Programs={stores.Programs} Lessons={stores.Lessons}>
  <BrowserRouter forceRefresh={true}>
    <React.StrictMode>

      <App />

    </React.StrictMode>
  </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
