import clientConfig from '../../../../../../client-config';
const ProgramsUrl = clientConfig.ProgramsUrl;
const FullPath = `${ProgramsUrl}/progrmas/PLAY_MACHINES_2/BuildingInstructions_Images/MainModels`

const Pic1 = `${FullPath}/ContinuousTurningFan/1_1x.png`;
const Pic2 = `${FullPath}/ContinuousTurningFan/2_1x.png`;
const Pic3 = `${FullPath}/ContinuousTurningFan/3_1x.png`;
const Pic4 = `${FullPath}/ContinuousTurningFan/4_1x.png`;
const Pic5 = `${FullPath}/ContinuousTurningFan/5_1x.png`;
const Pic6 = `${FullPath}/ContinuousTurningFan/6_1x.png`;
const Pic7 = `${FullPath}/ContinuousTurningFan/7_1x.png`;
const Pic8 = `${FullPath}/ContinuousTurningFan/8_1x.png`;
const Pic9 = `${FullPath}/ContinuousTurningFan/9_1x.png`;
const Pic10 = `${FullPath}/ContinuousTurningFan/10_1x.png`;
const Pic11 = `${FullPath}/ContinuousTurningFan/11_1x.png`;
const Pic12 = `${FullPath}/ContinuousTurningFan/12_1x.png`;
const Pic13 = `${FullPath}/ContinuousTurningFan/13_1x.png`;
const Pic14 = `${FullPath}/ContinuousTurningFan/14_1x.png`;
const Pic15 = `${FullPath}/ContinuousTurningFan/15_1x.png`;
const Pic16 = `${FullPath}/ContinuousTurningFan/16_1x.png`;
const Pic17 = `${FullPath}/ContinuousTurningFan/17_1x.png`;
const Pic18 = `${FullPath}/ContinuousTurningFan/18_1x.png`;
const Pic19 = `${FullPath}/ContinuousTurningFan/19_1x.png`;
const Pic20 = `${FullPath}/ContinuousTurningFan/20_1x.png`;
const Pic21 = `${FullPath}/ContinuousTurningFan/21_1x.png`;
const Pic22 = `${FullPath}/ContinuousTurningFan/22_1x.png`;
const Pic23 = `${FullPath}/ContinuousTurningFan/23_1x.png`;
const Pic24 = `${FullPath}/ContinuousTurningFan/24_1x.png`;
const Pic25 = `${FullPath}/ContinuousTurningFan/25_1x.png`;
const Pic26 = `${FullPath}/ContinuousTurningFan/26_1x.png`;
const Pic27 = `${FullPath}/ContinuousTurningFan/27_1x.png`;
const Pic28 = `${FullPath}/ContinuousTurningFan/28_1x.png`;
const Pic29 = `${FullPath}/ContinuousTurningFan/29_1x.png`;
const Pic30 = `${FullPath}/ContinuousTurningFan/30_1x.png`;
const Pic31 = `${FullPath}/ContinuousTurningFan/31_1x.png`;
const Pic32 = `${FullPath}/ContinuousTurningFan/32_1x.png`;
const Pic33 = `${FullPath}/ContinuousTurningFan/33_1x.png`;
const Pic34 = `${FullPath}/ContinuousTurningFan/34_1x.png`;
const Pic35 = `${FullPath}/ContinuousTurningFan/35_1x.png`;
const Pic36 = `${FullPath}/ContinuousTurningFan/36_1x.png`;
const Pic37 = `${FullPath}/ContinuousTurningFan/37_1x.png`;
const Pic38 = `${FullPath}/ContinuousTurningFan/38_1x.png`;
const Pic39 = `${FullPath}/ContinuousTurningFan/39_1x.png`;
const Pic40 = `${FullPath}/ContinuousTurningFan/40_1x.png`;
const Pic41 = `${FullPath}/ContinuousTurningFan/41_1x.png`;
const Pic42 = `${FullPath}/ContinuousTurningFan/42_1x.png`;



export const Buildingimages = [

]
    const Pics = [
        Pic1,
        Pic2,
        Pic3,
        Pic4,
        Pic5,
        Pic6,
        Pic7,
        Pic8,
        Pic9,
        Pic10,
        Pic11,
        Pic12,
        Pic13,
        Pic14,
        Pic15,
        Pic16,
        Pic17,
        Pic18,
        Pic19,
        Pic20,
        Pic21,
        Pic22,
        Pic23,
        Pic24,
        Pic25,
        Pic26,
        Pic27,
        Pic28,
        Pic29,
        Pic30,
        Pic31,
        Pic32,
        Pic33,
        Pic34,
        Pic35,
        Pic36,
        Pic37,
        Pic38,
        Pic39,
        Pic40,
        Pic41,
        Pic42,
      
    

    ]


    for (let i = 0; i < Pics.length; i++) {
        let obj = {};
        obj['original'] = Pics[i];
        obj['thumbnail'] = Pics[i];
        Buildingimages.push(obj);
    }

export default {
    Buildingimages,
}