export const isLoggedIn = () => {

	return localStorage.getItem('token');
};

export const getUserName = () => (
	localStorage.getItem('userName')
);


export const isFranchiseOwner = () => {

	if (localStorage.getItem('userRoles') === 'franchise_owner' || localStorage.getItem('userRoles') === 'administrator') {
		return true
	}
	else
		return false
}


export const isAdministrator = () => {

	if (localStorage.getItem('userRoles') === 'administrator') {
		return true
	}
	else
		return false
}


export const isStudent = () => {

	if (localStorage.getItem('userRoles') === 'student') {
		return true
	}
	else
		return false
}



