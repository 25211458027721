import React, { Component } from 'react';
import Vimeo from '@u-wave/react-vimeo';
import { observable, configure, runInAction, action, toJS } from 'mobx';
import parse from 'html-react-parser'
import axios from 'axios';
import clientConfig from '../client-config';


import { GoPlay } from "react-icons/go";

configure({ enforceActions: true });


class Lessons {
    @observable loading = false
    @observable FatchEnd = false
    @observable AllLessons = []
    @observable MainModels = []
    @observable ExperimentalModels = []

    // TABS VIEW
    @observable ShowInDiffrentTabs = false

    @observable Tab1Content = []
    @observable Tab1Title = []

    @observable Tab2Content = []
    @observable Tab2Title = []

    @observable Tab3Content = []
    @observable Tab3Title = []

    @observable Tab4Content = []
    @observable Tab4Title = []

    @observable Tab5Content = []
    @observable Tab5Title = []

    @observable NumberOfTabsWithContent = 0


    @observable Plm1 = []
    @observable Plm1Experimental = []
    @observable Plm2 = []
    @observable Plm2Experimental = []
    @observable LessonParentProgram = ''
    @observable LessonTitle = ''
    @observable LessonData = []
    @observable WordpressId = ''
    @observable showVideo = {}
    @observable showPlayButton = true
    @observable showGalleryPlayButton = true
    @observable showFullscreenButton = true
    @observable showGalleryFullscreenButton = true
    // @observable onSlide = []





    // Program ID FROM WORDPRESS (the parent page of all lessons)
    @action UpdatePlm1() {
        this.WordpressId = '130'
        this.LoadLessons()
    }

    @action UpdatePlm2() {
        this.WordpressId = '167'
        this.LoadLessons()
    }

    @action UpdateLessons(ID) {
        this.WordpressId = ID

        this.LoadLessons()

    }


    @action UpdateLessonData(LessonId) {
        this.WordpressId = LessonId
        this.LoadSingleLesson()
    }



    @action
    onSlide(index) {

        this.resetVideo()
    }

    // @action
    // onImageClick(event) {
    //     console.log(event)
    // }








    @action
    resetVideo() {
        this.showVideo = {}

        if (this.showPlayButton) {
            this.showGalleryPlayButton = true
        }

        if (this.showFullscreenButton) {
            this.showGalleryFullscreenButton = true
        }
    }



    LoadLessons() {
        const wordPressSiteUrl = clientConfig.siteUrl;

        this.loading = true
        axios.get(`${wordPressSiteUrl}/wp-json/wp/v2/pages?parent=${this.WordpressId}&per_page=100`)
            .then(res => {
                const AllModels = res.data;
                const FilterMainModels = AllModels.filter(page => page.metadata.experimental_model[0] === "0");
                FilterMainModels.sort((a, b) => (a.metadata.order - b.metadata.order))

                const FilterExperimentalModels = AllModels.filter(page => page.metadata.experimental_model[0] === "1");
              
                FilterExperimentalModels.sort((a, b) => (a.metadata.order - b.metadata.order))

                // const Plm1Lessons = res.data.filter(page => page.program_name === "play-machines-1");
                // Plm1Lessons.sort((a, b) => (a.metadata.order - b.metadata.order))

                // const Plm1MainModels = Plm1Lessons.filter(page => page.metadata.experimental_model[0] === "0");
                // const Plm1Experimental = Plm1Lessons.filter(page => page.metadata.experimental_model[0] === "1");


                // const Plm2Lessons = res.data.filter(page => page.program_name === "play-machines-2");
                // Plm2Lessons.sort((a, b) => (a.metadata.order - b.metadata.order))

                // const Plm2MainModels = Plm2Lessons.filter(page => page.metadata.experimental_model[0] === "0");
                // const Plm2Experimental = Plm2Lessons.filter(page => page.metadata.experimental_model[0] === "1");


                runInAction(() => {
                    this.loading = false;
                    this.AllLessons = res.data;
                    this.MainModels = FilterMainModels;
                    this.ExperimentalModels = FilterExperimentalModels;

                    // this.Plm1 = Plm1MainModels;
                    // this.Plm1Experimental = Plm1Experimental;

                    // this.Plm2 = Plm2MainModels
                    // this.Plm2Experimental = Plm2Experimental;

                    this.FatchEnd = true;

                });
            })
            .catch(err => {
                runInAction(() => {
                    this.loading = false
                    console.log('LoadLessons ERR')
                });
            })

    }







    renderVideo(item) {
        return (
            <div>
                {
                    this.showVideo[item.embedUrl] ?
                        <div className='video-wrapper'>
                            <a
                                className='close-video'
                                onClick={this.toggleShowVideo.bind(this, item.embedUrl)}
                            >
                            </a>


                            <div class="video_holder">
                                <img src="https://play.youngengineers.org/shop/wp-content/uploads/2020/07/VideoHolder1920X1080.png" />
                                <Vimeo
                                    video={item.embedUrl}
                                    autoplay
                                    className='vimeo_iframe'
                                />
                            </div>


                        </div>
                        :
                        <a onClick={this.toggleShowVideo.bind(this, item.embedUrl)}>
                            {/* <div className='play-button'></div> */}
                            <GoPlay className='play-button' />
                            <img className='image-gallery-image' src={item.original} />
                            {
                                item.description &&
                                <span
                                    className='image-gallery-description'
                                    style={{ right: '0', left: 'initial' }}
                                >
                                    {item.description}
                                </span>
                            }
                        </a>
                }
            </div>
        );
    }

    @action
    toggleShowVideo(url) {
        this.showVideo[url] = !Boolean(this.showVideo[url]);
        this.showVideo = this.showVideo

        if (this.showVideo[url]) {
            if (this.showPlayButton) {
                this.showGalleryPlayButton = false
            }

            if (this.showFullscreenButton) {
                this.showGalleryFullscreenButton = false
            }
        }
    }



    LoadSingleLesson() {
        const wordPressSiteUrl = clientConfig.siteUrl;

        this.loading = true
        axios.get(`${wordPressSiteUrl}/wp-json/wp/v2/pages/${this.WordpressId}`)
            .then(res => {

                let FullLesson = []
                let Tab1 = []
                let Tab2 = []
                let Tab3 = []
                let Tab4 = []
                let Tab5 = []





                const GenericFrame = `${wordPressSiteUrl}/wp-content/uploads/2020/06/GenericPlay.jpg`

                const video_frame0 = res.data.lesson_0_video_frame.url
                const lesson_0_videoid = res.data.metadata.lesson_0_videoid
                // console.log(lesson_0_videoid[0])
                if (lesson_0_videoid && lesson_0_videoid[0] !== '') {
                    let obj = {};
                    obj['original'] = video_frame0 ? video_frame0 : GenericFrame;
                    obj['thumbnail'] = video_frame0 ? video_frame0 : GenericFrame;
                    obj['embedUrl'] = 'https://player.vimeo.com/video/' + lesson_0_videoid[0];
                    obj['renderItem'] = this.renderVideo.bind(this);
                    FullLesson.push(obj);
                    Tab1.push(obj);
                }



                const lesson_0_gallery = res.data.lesson_0_gallery
                if (lesson_0_gallery) {
                    for (let i = 0; i < lesson_0_gallery.length; i++) {
                        let obj = {};
                        obj['original'] = lesson_0_gallery[i];
                        obj['thumbnail'] = lesson_0_gallery[i];
                        FullLesson.push(obj);
                        Tab1.push(obj);
                    }
                }


                const video_frame1 = res.data.lesson_1_video_frame.url
                const lesson_1_videoid = res.data.metadata.lesson_1_videoid
                if (lesson_1_videoid && lesson_1_videoid[0] !== '') {
                    let obj = {};
                    obj['original'] = video_frame1 ? video_frame1 : GenericFrame;
                    obj['thumbnail'] = video_frame1 ? video_frame1 : GenericFrame;
                    obj['embedUrl'] = 'https://player.vimeo.com/video/' + lesson_1_videoid[0];
                    obj['renderItem'] = this.renderVideo.bind(this);
                    FullLesson.push(obj);
                    Tab2.push(obj);
                }

                const lesson_1_gallery = res.data.lesson_1_gallery
                if (lesson_1_gallery) {
                    for (let i = 0; i < lesson_1_gallery.length; i++) {
                        let obj = {};
                        obj['original'] = lesson_1_gallery[i];
                        obj['thumbnail'] = lesson_1_gallery[i];
                        FullLesson.push(obj);
                        Tab2.push(obj);
                    }
                }

                const video_frame2 = res.data.lesson_2_video_frame.url
                const lesson_2_videoid = res.data.metadata.lesson_2_videoid

                if (lesson_2_videoid && lesson_2_videoid[0] !== '') {
                    let obj = {};
                    obj['original'] = video_frame2 ? video_frame2 : GenericFrame;
                    obj['thumbnail'] = video_frame2 ? video_frame2 : GenericFrame;
                    obj['embedUrl'] = 'https://player.vimeo.com/video/' + lesson_2_videoid[0];
                    obj['renderItem'] = this.renderVideo.bind(this);
                    FullLesson.push(obj);
                    Tab3.push(obj);
                }



                const lesson_2_gallery = res.data.lesson_2_gallery
                if (lesson_2_gallery) {
                    for (let i = 0; i < lesson_2_gallery.length; i++) {
                        let obj = {};
                        obj['original'] = lesson_2_gallery[i];
                        obj['thumbnail'] = lesson_2_gallery[i];
                        FullLesson.push(obj);
                        Tab3.push(obj);
                    }
                }



                const video_frame3 = res.data.lesson_3_video_frame.url
                const lesson_3_videoid = res.data.metadata.lesson_3_videoid

                if (lesson_3_videoid && lesson_3_videoid[0] !== '') {
                    let obj = {};
                    obj['original'] = video_frame3 ? video_frame3 : GenericFrame;
                    obj['thumbnail'] = video_frame3 ? video_frame3 : GenericFrame;
                    obj['embedUrl'] = 'https://player.vimeo.com/video/' + lesson_3_videoid[0];
                    obj['renderItem'] = this.renderVideo.bind(this);
                    FullLesson.push(obj);
                    Tab4.push(obj);
                }





                const lesson_3_gallery = res.data.lesson_3_gallery
                if (lesson_3_gallery) {
                    for (let i = 0; i < lesson_3_gallery.length; i++) {
                        let obj = {};
                        obj['original'] = lesson_3_gallery[i];
                        obj['thumbnail'] = lesson_3_gallery[i];
                        FullLesson.push(obj);
                        Tab4.push(obj);
                    }
                }



                const video_frame4 = res.data.lesson_4_video_frame.url
                const lesson_4_videoid = res.data.metadata.lesson_4_videoid

                if (lesson_4_videoid && lesson_4_videoid[0] !== '') {
                    let obj = {};
                    obj['original'] = video_frame4 ? video_frame4 : GenericFrame;
                    obj['thumbnail'] = video_frame4 ? video_frame4 : GenericFrame;
                    obj['embedUrl'] = 'https://player.vimeo.com/video/' + lesson_4_videoid[0];
                    obj['renderItem'] = this.renderVideo.bind(this);
                    FullLesson.push(obj);
                    Tab5.push(obj);
                }


                const lesson_4_gallery = res.data.lesson_4_gallery
                if (lesson_4_gallery) {
                    for (let i = 0; i < lesson_4_gallery.length; i++) {
                        let obj = {};
                        obj['original'] = lesson_4_gallery[i];
                        obj['thumbnail'] = lesson_4_gallery[i];
                        FullLesson.push(obj);
                        Tab5.push(obj);
                    }
                }

                // console.log(res.data.title.rendered)

                runInAction(() => {
                    this.loading = false;
                    this.LessonData = FullLesson;
                    this.LessonTitle = res.data.title.rendered;
                    this.LessonParentProgram = res.data.program_name;

                    if (res.data.metadata.show_in_diffrent_tabs[0] === '0') {
                        this.ShowInDiffrentTabs = false
                    }
                    if (res.data.metadata.show_in_diffrent_tabs[0] === '1') {
                        this.ShowInDiffrentTabs = true
                    }

                    if (Tab1.length > 0) {
                        this.Tab1Content = Tab1
                        this.Tab1Title = res.data.metadata.lesson_0_tab_title[0]
                        this.NumberOfTabsWithContent = this.NumberOfTabsWithContent + 1
                    } else (
                        this.Tab1Title = null
                    )

                    if (Tab2.length > 0) {
                        this.Tab2Content = Tab2
                        this.Tab2Title = res.data.metadata.lesson_1_tab_title[0]
                        this.NumberOfTabsWithContent = this.NumberOfTabsWithContent + 1
                    } else (
                        this.Tab2Title = null
                    )


                    if (Tab3.length > 0) {
                        this.Tab3Content = Tab3
                        this.Tab3Title = res.data.metadata.lesson_2_tab_title[0]
                        this.NumberOfTabsWithContent = this.NumberOfTabsWithContent + 1
                    } else (
                        this.Tab3Title = null
                    )


                    if (Tab4.length > 0) {
                        this.Tab4Content = Tab4
                        this.Tab4Title = res.data.metadata.lesson_3_tab_title[0]
                        this.NumberOfTabsWithContent = this.NumberOfTabsWithContent + 1
                    } else (
                        this.Tab4Title = null
                    )

                    if (Tab5.length > 0) {
                        this.Tab5Content = Tab5
                        this.Tab5Title = res.data.metadata.lesson_4_tab_title[0]
                        this.NumberOfTabsWithContent = this.NumberOfTabsWithContent + 1
                    } else (
                        this.Tab5Title = null
                    )


                });
            })
            .catch(err => {
                runInAction(() => {
                    this.loading = false
                    console.log('LoadLessons ERR')
                });
            })

    }






    constructor() {

    };



};







export default new Lessons();





