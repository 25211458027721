import clientConfig from '../../../../../../client-config';
const ProgramsUrl = clientConfig.ProgramsUrl;
const FullPath = `${ProgramsUrl}/progrmas/PLAY_MACHINES_2/BuildingInstructions_Images/MainModels`

const Pic1 = `${FullPath}/PortableClawMachine/1_1x.png`;
const Pic2 = `${FullPath}/PortableClawMachine/2_1x.png`;
const Pic3 = `${FullPath}/PortableClawMachine/3_1x.png`;
const Pic4 = `${FullPath}/PortableClawMachine/4_1x.png`;
const Pic5 = `${FullPath}/PortableClawMachine/5_1x.png`;
const Pic6 = `${FullPath}/PortableClawMachine/6_1x.png`;
const Pic7 = `${FullPath}/PortableClawMachine/7_1x.png`;
const Pic8 = `${FullPath}/PortableClawMachine/8_1x.png`;
const Pic9 = `${FullPath}/PortableClawMachine/9_1x.png`;
const Pic10 = `${FullPath}/PortableClawMachine/10_1x.png`;
const Pic11 = `${FullPath}/PortableClawMachine/11_1x.png`;
const Pic12 = `${FullPath}/PortableClawMachine/12_1x.png`;
const Pic13 = `${FullPath}/PortableClawMachine/13_1x.png`;
const Pic14 = `${FullPath}/PortableClawMachine/14_1x.png`;
const Pic15 = `${FullPath}/PortableClawMachine/15_1x.png`;
const Pic16 = `${FullPath}/PortableClawMachine/16_1x.png`;
const Pic17 = `${FullPath}/PortableClawMachine/17_1x.png`;
const Pic18 = `${FullPath}/PortableClawMachine/18_1x.png`;
const Pic19 = `${FullPath}/PortableClawMachine/19_1x.png`;
const Pic20 = `${FullPath}/PortableClawMachine/20_1x.png`;
const Pic21 = `${FullPath}/PortableClawMachine/21_1x.png`;
const Pic22 = `${FullPath}/PortableClawMachine/22_1x.png`;
const Pic23 = `${FullPath}/PortableClawMachine/23_1x.png`;
const Pic24 = `${FullPath}/PortableClawMachine/24_1x.png`;
const Pic25 = `${FullPath}/PortableClawMachine/25_1x.png`;
const Pic26 = `${FullPath}/PortableClawMachine/26_1x.png`;
const Pic27 = `${FullPath}/PortableClawMachine/27_1x.png`;
const Pic28 = `${FullPath}/PortableClawMachine/28_1x.png`;
const Pic29 = `${FullPath}/PortableClawMachine/29_1x.png`;
const Pic30 = `${FullPath}/PortableClawMachine/30_1x.png`;
const Pic31 = `${FullPath}/PortableClawMachine/31_1x.png`;
const Pic32 = `${FullPath}/PortableClawMachine/32_1x.png`;
const Pic33 = `${FullPath}/PortableClawMachine/33_1x.png`;
const Pic34 = `${FullPath}/PortableClawMachine/34_1x.png`;
const Pic35 = `${FullPath}/PortableClawMachine/35_1x.png`;
const Pic36 = `${FullPath}/PortableClawMachine/36_1x.png`;
const Pic37 = `${FullPath}/PortableClawMachine/37_1x.png`;
const Pic38 = `${FullPath}/PortableClawMachine/38_1x.png`;
const Pic39 = `${FullPath}/PortableClawMachine/39_1x.png`;
const Pic40 = `${FullPath}/PortableClawMachine/40_1x.png`;
const Pic41 = `${FullPath}/PortableClawMachine/41_1x.png`;
const Pic42 = `${FullPath}/PortableClawMachine/42_1x.png`;
const Pic43 = `${FullPath}/PortableClawMachine/43_1x.png`;
const Pic44 = `${FullPath}/PortableClawMachine/44_1x.png`;
const Pic45 = `${FullPath}/PortableClawMachine/45_1x.png`;
const Pic46 = `${FullPath}/PortableClawMachine/46_1x.png`;
const Pic47 = `${FullPath}/PortableClawMachine/47_1x.png`;
const Pic48 = `${FullPath}/PortableClawMachine/48_1x.png`;
const Pic49 = `${FullPath}/PortableClawMachine/49_1x.png`;
const Pic50 = `${FullPath}/PortableClawMachine/50_1x.png`;
const Pic51 = `${FullPath}/PortableClawMachine/51_1x.png`;
const Pic52 = `${FullPath}/PortableClawMachine/52_1x.png`;



export const Buildingimages = [

]
    const Pics = [
        Pic1,
        Pic2,
        Pic3,
        Pic4,
        Pic5,
        Pic6,
        Pic7,
        Pic8,
        Pic9,
        Pic10,
        Pic11,
        Pic12,
        Pic13,
        Pic14,
        Pic15,
        Pic16,
        Pic17,
        Pic18,
        Pic19,
        Pic20,
        Pic21,
        Pic22,
        Pic23,
        Pic24,
        Pic25,
        Pic26,
        Pic27,
        Pic28,
        Pic29,
        Pic30,
        Pic31,
        Pic32,
        Pic33,
        Pic34,
        Pic35,
        Pic36,
        Pic37,
        Pic38,
        Pic39,
        Pic40,
        Pic41,
        Pic42,
        Pic43,
        Pic44,
        Pic45,
        Pic46,
        Pic47,
        Pic48,
        Pic49,
        Pic50,
        Pic51,
        Pic52,
  
    ]


    for (let i = 0; i < Pics.length; i++) {
        let obj = {};
        obj['original'] = Pics[i];
        obj['thumbnail'] = Pics[i];
        Buildingimages.push(obj);
    }

export default {
    Buildingimages,
}