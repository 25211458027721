import React from 'react';
import styles from './PopupLink.module.css';

import NavLink from '../NavLink';
import { Redirect, Link } from 'react-router-dom'

import { RiAccountCircleLine } from "react-icons/ri"

import './Navbar.css'



class PopUpLink extends React.Component {




    render() {


        return (

            <NavLink className={styles.PopUpLink} to={this.props.LinkTo}>
                <div className={styles.Link} style={{ marginTop: this.props.MarginTop }}>
                    <div className={styles.LinkIcon} style={{ left: this.props.Left, fontSize: this.props.FontSize, top: this.props.Top }}>
                        {this.props.Icon}
                    </div>
                    <div className={styles.LinkText} style={{ marginLeft: '10px' }}>
                        {this.props.Text}
                    </div>
                </div>
            </NavLink>

        );
    }
}

export default (PopUpLink);


