import clientConfig from '../../../../../../client-config';
const ProgramsUrl = clientConfig.ProgramsUrl;
const FullPath = `${ProgramsUrl}/progrmas/PLAY_MACHINES_1/BuildingInstructions_Images/MainModels`

const Pic1 = `${FullPath}/SpinningTopLauncher/1_1x.png`;
const Pic2 = `${FullPath}/SpinningTopLauncher/2_1x.png`;
const Pic3 = `${FullPath}/SpinningTopLauncher/3_1x.png`;
const Pic4 = `${FullPath}/SpinningTopLauncher/4_1x.png`;
const Pic5 = `${FullPath}/SpinningTopLauncher/5_1x.png`;
const Pic6 = `${FullPath}/SpinningTopLauncher/6_1x.png`;
const Pic7 = `${FullPath}/SpinningTopLauncher/7_1x.png`;
const Pic8 = `${FullPath}/SpinningTopLauncher/8_1x.png`;
const Pic9 = `${FullPath}/SpinningTopLauncher/9_1x.png`;
const Pic10 = `${FullPath}/SpinningTopLauncher/10_1x.png`;
const Pic11 = `${FullPath}/SpinningTopLauncher/11_1x.png`;
const Pic12 = `${FullPath}/SpinningTopLauncher/12_1x.png`;
const Pic13 = `${FullPath}/SpinningTopLauncher/13_1x.png`;
const Pic14 = `${FullPath}/SpinningTopLauncher/14_1x.png`;
// const Pic15 = `${FullPath}/SpinningTopLauncher/15_1x.png`;
// const Pic16 = `${FullPath}/SpinningTopLauncher/16_1x.png`;
// const Pic17 = `${FullPath}/SpinningTopLauncher/17_1x.png`;
// const Pic18 = `${FullPath}/SpinningTopLauncher/18_1x.png`;
// const Pic19 = `${FullPath}/SpinningTopLauncher/19_1x.png`;
// const Pic20 = `${FullPath}/SpinningTopLauncher/20_1x.png`;
// const Pic21 = `${FullPath}/SpinningTopLauncher/21_1x.png`;
// const Pic22 = `${FullPath}/SpinningTopLauncher/22_1x.png`;
// const Pic23 = `${FullPath}/SpinningTopLauncher/23_1x.png`;
// const Pic24 = `${FullPath}/SpinningTopLauncher/24_1x.png`;
// const Pic25 = `${FullPath}/SpinningTopLauncher/25_1x.png`;
const Pic26 = `${FullPath}/SpinningTopLauncher/26_1x.png`;








export const Buildingimages = [

]
    const Pics = [
        Pic1,
        Pic2,
        Pic3,
        Pic4,
        Pic5,
        Pic6,
        Pic7,
        Pic8,
        Pic9,
        Pic10,
        Pic11,
        Pic12,
        Pic13,
        Pic14,
        // Pic15,
        // Pic16,
        // Pic17,
        // Pic18,
        // Pic19,
        // Pic20,
        // Pic21,
        // Pic22,
        // Pic23,
        // Pic24,
        // Pic25,
        Pic26,
    ]


    for (let i = 0; i < Pics.length; i++) {
        let obj = {};
        obj['original'] = Pics[i];
        obj['thumbnail'] = Pics[i];
        Buildingimages.push(obj);
    }

export default {
    Buildingimages,
}