import clientConfig from '../../../../../../client-config';
const ProgramsUrl = clientConfig.ProgramsUrl;
const FullPath = `${ProgramsUrl}/progrmas/PLAY_MACHINES_2/BuildingInstructions_Images/ExplorationModels`

const Pic1 = `${FullPath}/ContinuousBackandForth/1_1x.png`;
const Pic2 = `${FullPath}/ContinuousBackandForth/2_1x.png`;
const Pic3 = `${FullPath}/ContinuousBackandForth/3_1x.png`;
const Pic4 = `${FullPath}/ContinuousBackandForth/4_1x.png`;
const Pic5 = `${FullPath}/ContinuousBackandForth/5_1x.png`;
const Pic6 = `${FullPath}/ContinuousBackandForth/6_1x.png`;
const Pic7 = `${FullPath}/ContinuousBackandForth/7_1x.png`;
const Pic8 = `${FullPath}/ContinuousBackandForth/8_1x.png`;
const Pic9 = `${FullPath}/ContinuousBackandForth/9_1x.png`;
const Pic10 = `${FullPath}/ContinuousBackandForth/10_1x.png`;
const Pic11 = `${FullPath}/ContinuousBackandForth/11_1x.png`;
const Pic12 = `${FullPath}/ContinuousBackandForth/12_1x.png`;
const Pic13 = `${FullPath}/ContinuousBackandForth/13_1x.png`;
const Pic14 = `${FullPath}/ContinuousBackandForth/14_1x.png`;
const Pic15 = `${FullPath}/ContinuousBackandForth/15_1x.png`;
const Pic16 = `${FullPath}/ContinuousBackandForth/16_1x.png`;
const Pic17 = `${FullPath}/ContinuousBackandForth/17_1x.png`;
const Pic18 = `${FullPath}/ContinuousBackandForth/18_1x.png`;
const Pic19 = `${FullPath}/ContinuousBackandForth/19_1x.png`;
const Pic20 = `${FullPath}/ContinuousBackandForth/20_1x.png`;
const Pic21 = `${FullPath}/ContinuousBackandForth/21_1x.png`;
const Pic22 = `${FullPath}/ContinuousBackandForth/22_1x.png`;
const Pic23 = `${FullPath}/ContinuousBackandForth/23_1x.png`;
const Pic24 = `${FullPath}/ContinuousBackandForth/24_1x.png`;
const Pic25 = `${FullPath}/ContinuousBackandForth/25_1x.png`;
const Pic26 = `${FullPath}/ContinuousBackandForth/26_1x.png`;
const Pic27 = `${FullPath}/ContinuousBackandForth/27_1x.png`;
const Pic28 = `${FullPath}/ContinuousBackandForth/28_1x.png`;
const Pic29 = `${FullPath}/ContinuousBackandForth/29_1x.png`;
const Pic30 = `${FullPath}/ContinuousBackandForth/30_1x.png`;
const Pic31 = `${FullPath}/ContinuousBackandForth/31_1x.png`;
const Pic32 = `${FullPath}/ContinuousBackandForth/32_1x.png`;
const Pic33 = `${FullPath}/ContinuousBackandForth/33_1x.png`;
const Pic34 = `${FullPath}/ContinuousBackandForth/34_1x.png`;
const Pic35 = `${FullPath}/ContinuousBackandForth/35_1x.png`;

export const Buildingimages = [

]
    const Pics = [
        Pic1,
        Pic2,
        Pic3,
        Pic4,
        Pic5,
        Pic6,
        Pic7,
        Pic8,
        Pic9,
        Pic10,
        Pic11,
        Pic12,
        Pic13,
        Pic14,
        Pic15,
        Pic16,
        Pic17,
        Pic18,
        Pic19,
        Pic20,
        Pic21,
        Pic22,
        Pic23,
        Pic24,
        Pic25,
        Pic26,
        Pic27,
        Pic28,
        Pic29,
        Pic30,
        Pic31,
        Pic32,
        Pic33,
        Pic34,
        Pic35,


    ]


    for (let i = 0; i < Pics.length; i++) {
        let obj = {};
        obj['original'] = Pics[i];
        obj['thumbnail'] = Pics[i];
        Buildingimages.push(obj);
    }

export default {
    Buildingimages,
}