import React from 'react';
import { Link } from 'react-router-dom'
import styles from './Modelitem.module.css';



class ModelItem extends React.Component {


    render() {








        return (

            <>

                <div className={styles.Item} key={this.props.ItemId}>
                 
                        <div className={styles.TitleContainer}>
                            <div className={styles.Title + ' ' + this.props.TitleclassName}>{this.props.Title}</div>
                            <img className={styles.TopTitleBackground} src='https://play.youngengineers.org/shop/wp-content/uploads/2020/06/top_flat.png' alt="TopTitleBackground"></img>
                        </div>

                        <div className={styles.ModelPic}>
                            <img className={styles.TopTitleBackground} src='https://play.youngengineers.org/shop/wp-content/uploads/2020/06/smallpic_flat.png' alt="ModelPic"></img>
                        </div>


                        <div className={styles.BottomAreaFlat}>
                            
                        </div>


                        <div className={styles.BottomShadow}>
                            <img className={styles.TopTitleBackground} src='https://play.youngengineers.org/shop/wp-content/uploads/2020/06/BottomShadowEmpty.png' alt="BottomShadow"></img>
                        </div>
                  
                </div>


            </>

        );
    }
}

export default ModelItem;