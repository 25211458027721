import clientConfig from '../../../../../../client-config';
const ProgramsUrl = clientConfig.ProgramsUrl;
const FullPath = `${ProgramsUrl}/progrmas/PLAY_MACHINES_2/BuildingInstructions_Images/ExplorationModels`

const Pic1 = `${FullPath}/WormGear/1_1x.png`;
const Pic2 = `${FullPath}/WormGear/2_1x.png`;
const Pic3 = `${FullPath}/WormGear/3_1x.png`;
const Pic4 = `${FullPath}/WormGear/4_1x.png`;
const Pic5 = `${FullPath}/WormGear/5_1x.png`;
const Pic6 = `${FullPath}/WormGear/6_1x.png`;
const Pic7 = `${FullPath}/WormGear/7_1x.png`;
const Pic8 = `${FullPath}/WormGear/8_1x.png`;
const Pic9 = `${FullPath}/WormGear/9_1x.png`;
const Pic10 = `${FullPath}/WormGear/10_1x.png`;
const Pic11 = `${FullPath}/WormGear/11_1x.png`;
const Pic12 = `${FullPath}/WormGear/12_1x.png`;
const Pic13 = `${FullPath}/WormGear/13_1x.png`;
const Pic14 = `${FullPath}/WormGear/14_1x.png`;



export const Buildingimages = [

]
    const Pics = [
        Pic1,
        Pic2,
        Pic3,
        Pic4,
        Pic5,
        Pic6,
        Pic7,
        Pic8,
        Pic9,
        Pic10,
        Pic11,
        Pic12,
        Pic13,
        Pic14,
 

    ]


    for (let i = 0; i < Pics.length; i++) {
        let obj = {};
        obj['original'] = Pics[i];
        obj['thumbnail'] = Pics[i];
        Buildingimages.push(obj);
    }

export default {
    Buildingimages,
}