import clientConfig from '../../../../../../client-config';
const ProgramsUrl = clientConfig.ProgramsUrl;
const FullPath = `${ProgramsUrl}/progrmas/PLAY_MACHINES_2/BuildingInstructions_Images/MainModels`

const Pic1 = `${FullPath}/TeaCupFamilyRide/1_1x.png`;
const Pic2 = `${FullPath}/TeaCupFamilyRide/2_1x.png`;
const Pic3 = `${FullPath}/TeaCupFamilyRide/3_1x.png`;
const Pic4 = `${FullPath}/TeaCupFamilyRide/4_1x.png`;
const Pic5 = `${FullPath}/TeaCupFamilyRide/5_1x.png`;
const Pic6 = `${FullPath}/TeaCupFamilyRide/6_1x.png`;
const Pic7 = `${FullPath}/TeaCupFamilyRide/7_1x.png`;
const Pic8 = `${FullPath}/TeaCupFamilyRide/8_1x.png`;
const Pic9 = `${FullPath}/TeaCupFamilyRide/9_1x.png`;
const Pic10 = `${FullPath}/TeaCupFamilyRide/10_1x.png`;
const Pic11 = `${FullPath}/TeaCupFamilyRide/11_1x.png`;
const Pic12 = `${FullPath}/TeaCupFamilyRide/12_1x.png`;
const Pic13 = `${FullPath}/TeaCupFamilyRide/13_1x.png`;
const Pic14 = `${FullPath}/TeaCupFamilyRide/14_1x.png`;
const Pic15 = `${FullPath}/TeaCupFamilyRide/15_1x.png`;
const Pic16 = `${FullPath}/TeaCupFamilyRide/16_1x.png`;
const Pic17 = `${FullPath}/TeaCupFamilyRide/17_1x.png`;
const Pic18 = `${FullPath}/TeaCupFamilyRide/18_1x.png`;
const Pic19 = `${FullPath}/TeaCupFamilyRide/19_1x.png`;
const Pic20 = `${FullPath}/TeaCupFamilyRide/20_1x.png`;
const Pic21 = `${FullPath}/TeaCupFamilyRide/21_1x.png`;
const Pic22 = `${FullPath}/TeaCupFamilyRide/22_1x.png`;
const Pic23 = `${FullPath}/TeaCupFamilyRide/23_1x.png`;
const Pic24 = `${FullPath}/TeaCupFamilyRide/24_1x.png`;
const Pic25 = `${FullPath}/TeaCupFamilyRide/25_1x.png`;
const Pic26 = `${FullPath}/TeaCupFamilyRide/26_1x.png`;
const Pic27 = `${FullPath}/TeaCupFamilyRide/27_1x.png`;
const Pic28 = `${FullPath}/TeaCupFamilyRide/28_1x.png`;
const Pic29 = `${FullPath}/TeaCupFamilyRide/29_1x.png`;
const Pic30 = `${FullPath}/TeaCupFamilyRide/30_1x.png`;
const Pic31 = `${FullPath}/TeaCupFamilyRide/31_1x.png`;
const Pic32 = `${FullPath}/TeaCupFamilyRide/32_1x.png`;
const Pic33 = `${FullPath}/TeaCupFamilyRide/33_1x.png`;
const Pic34 = `${FullPath}/TeaCupFamilyRide/34_1x.png`;
const Pic35 = `${FullPath}/TeaCupFamilyRide/35_1x.png`;
const Pic36 = `${FullPath}/TeaCupFamilyRide/36_1x.png`;
const Pic37 = `${FullPath}/TeaCupFamilyRide/37_1x.png`;
const Pic38 = `${FullPath}/TeaCupFamilyRide/38_1x.png`;
const Pic39 = `${FullPath}/TeaCupFamilyRide/39_1x.png`;
const Pic40 = `${FullPath}/TeaCupFamilyRide/40_1x.png`;
const Pic41 = `${FullPath}/TeaCupFamilyRide/41_1x.png`;
const Pic42 = `${FullPath}/TeaCupFamilyRide/42_1x.png`;
// const Pic43 = `${FullPath}/TeaCupFamilyRide/43_1x.png`;
const Pic44 = `${FullPath}/TeaCupFamilyRide/44_1x.png`;
const Pic45 = `${FullPath}/TeaCupFamilyRide/45_1x.png`;



export const Buildingimages = [

]
    const Pics = [
        Pic1,
        Pic2,
        Pic3,
        Pic4,
        Pic5,
        Pic6,
        Pic7,
        Pic8,
        Pic9,
        Pic10,
        Pic11,
        Pic12,
        Pic13,
        Pic14,
        Pic15,
        Pic16,
        Pic17,
        Pic18,
        Pic19,
        Pic20,
        Pic21,
        Pic22,
        Pic23,
        Pic24,
        Pic25,
        Pic26,
        Pic27,
        Pic28,
        Pic29,
        Pic30,
        Pic31,
        Pic32,
        Pic33,
        Pic34,
        Pic35,
        Pic36,
        Pic37,
        Pic38,
        Pic39,
        Pic40,
        Pic41,
        Pic42,
        // Pic43,
        Pic44,
        Pic45,

    ]


    for (let i = 0; i < Pics.length; i++) {
        let obj = {};
        obj['original'] = Pics[i];
        obj['thumbnail'] = Pics[i];
        Buildingimages.push(obj);
    }

export default {
    Buildingimages,
}