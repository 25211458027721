
import React from 'react';
import styles from '../../../screens/SingleProgram/Programs.module.css';
import Modelitem from '../Modelitem';
import { getUserName } from "../../../components/functions";


import clientConfig from '../../../client-config';
const ProgramsUrl = clientConfig.ProgramsUrl;
const FullPath = `${ProgramsUrl}/progrmas/PLAY_MACHINES_2/SmallPics/MainModels`

const LawnMower = `${FullPath}/Lawn-Mower.png`;
const FortuneWheel = `${FullPath}/Fortune-Wheel.png`;
const ScissorLift = `${FullPath}/Scissor-Lift.png`;
const WormRobot = `${FullPath}/Worm-Robot.png`;
const Ballerina = `${FullPath}/Ballerina.png`;
const FamilyRide = `${FullPath}/Family-Ride.png`;
const OutdoorSwing = `${FullPath}/Outdoor-Swing.png`;
const ContinuousTurningFan = `${FullPath}/Continuous-Turning-Fan.png`;
const AlibabaAmusementRide = `${FullPath}/Alibaba.png`;
const PortableClawMachine = `${FullPath}/Portable-Claw-Machine.png`;



class ModelInstructionsTabContent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ManuIsOpen: false,
        }
    }

    render() {
        const userName = (getUserName()) ? getUserName() : '';
        const programName = 'PlayMachines2';
        
        return (
            <div className={styles.FlexContainer}>


                <div className={styles.ItemsContainer}>

                    {/* ITEM TO LOOP FROM DATA BASE */}
                    <Modelitem
                        Title="Lawn Mower"
                        TopPosition='0px'
                        ModelPic={LawnMower}
                        Password='2141'
                        userName={userName}
                        programName={programName}
                        TitleBackColor='green'
                        localStorageItemName='LawnMower'
                    />
                    {/* ITEM TO LOOP FROM DATA BASE */}


                    {/* ITEM TO LOOP FROM DATA BASE */}
                    <Modelitem
                        Title="Fortune Wheel"
                        TopPosition='0px'
                        ModelPic={FortuneWheel}
                        Password='3200'
                        userName={userName}
                        programName={programName}
                        TitleBackColor='green'
                        localStorageItemName='FortuneWheel'
                    />
                    {/* ITEM TO LOOP FROM DATA BASE */}

                    {/* ITEM TO LOOP FROM DATA BASE */}
                    <Modelitem
                        Title="Scissor Lift"
                        TopPosition='0px'
                        ModelPic={ScissorLift}
                        Password='7855'
                        userName={userName}
                        programName={programName}
                        TitleBackColor='green'
                        localStorageItemName='ScissorLift'
                    />
                    {/* ITEM TO LOOP FROM DATA BASE */}

                    {/* ITEM TO LOOP FROM DATA BASE */}
                    <Modelitem
                        Title="Worm Robot"
                        TopPosition='0px'
                        ModelPic={WormRobot}
                        Password='4348'
                        userName={userName}
                        programName={programName}
                        TitleBackColor='green'
                        localStorageItemName='WormRobot'
                    />
                    {/* ITEM TO LOOP FROM DATA BASE */}

                    {/* ITEM TO LOOP FROM DATA BASE */}
                    <Modelitem
                        Title="Ballerina"
                        TopPosition='0px'
                        ModelPic={Ballerina}
                        Password='0304'
                        userName={userName}
                        programName={programName}
                        TitleBackColor='green'
                        localStorageItemName='Ballerina'
                    />
                    {/* ITEM TO LOOP FROM DATA BASE */}

                    {/* ITEM TO LOOP FROM DATA BASE */}
                    <Modelitem
                        Title="Tea Cup Family Ride"
                        TopPosition='0px'
                        ModelPic={FamilyRide}
                        Password='5453'
                        userName={userName}
                        programName={programName}
                        TitleBackColor='green'
                        localStorageItemName='TeaCupFamilyRide'
                    />
                    {/* ITEM TO LOOP FROM DATA BASE */}

                    {/* ITEM TO LOOP FROM DATA BASE */}
                    <Modelitem
                        Title="Outdoor Swing"
                        TopPosition='0px'
                        ModelPic={OutdoorSwing}
                        Password='4367'
                        userName={userName}
                        programName={programName}
                        TitleBackColor='green'
                        localStorageItemName='OutdoorSwing'
                    />
                    {/* ITEM TO LOOP FROM DATA BASE */}

                    {/* ITEM TO LOOP FROM DATA BASE */}
                    <Modelitem
                        Title="Continuous Turning Fan"
                        TopPosition='0px'
                        ModelPic={ContinuousTurningFan}
                        Password='2478'
                        userName={userName}
                        programName={programName}
                        TitleBackColor='green'
                        localStorageItemName='ContinuousTurningFan'
                    />
                    {/* ITEM TO LOOP FROM DATA BASE */}

                    {/* ITEM TO LOOP FROM DATA BASE */}
                    <Modelitem
                        Title="Alibaba Amusement Ride"
                        TopPosition='0px'
                        ModelPic={AlibabaAmusementRide}
                        Password='4678'
                        userName={userName}
                        programName={programName}
                        TitleBackColor='green'
                        localStorageItemName='AlibabaAmusementRide'
                    />
                    {/* ITEM TO LOOP FROM DATA BASE */}

                    {/* ITEM TO LOOP FROM DATA BASE */}
                    <Modelitem
                        Title="Portable Claw Machine"
                        TopPosition='0px'
                        ModelPic={PortableClawMachine}
                        Password='4343'
                        userName={userName}
                        programName={programName}
                        TitleBackColor='green'
                        localStorageItemName='PortableClawMachine'
                    />
                    {/* ITEM TO LOOP FROM DATA BASE */}






                </div>


            </div>
        );
    }
}

export default ModelInstructionsTabContent;