import React from 'react';
import styles from './SelectProgram.module.css';
import './SelectProgram.css';

import ReactHtmlParser from 'react-html-parser';


import BottomShadow from '../images/BottomShadow.png'



class SelectProgramItem extends React.Component {




    render() {

        const style = {
            top: this.props.TopPosition,
            position: 'relative',
        };

        const html = this.props.Title

        return (

            <>
                <div className={styles.TitleContainer}>
                    {ReactHtmlParser(html)}
                    <img className={styles.TopTitleBackground} src={this.props.TopLegoImage} alt="TopTitleBackground"></img>
                </div>
            
                <div className={styles.ProgramLogo}>
                    <img className={styles.TopTitleBackground} style={style} src={this.props.ProgramLogo} alt="ProgramLogo"></img>
                </div>

                <div className={styles.BottomShadow}>
                    <img className={styles.TopTitleBackground} src={BottomShadow} alt="BottomShadow"></img>
                </div>

            </>

        );
    }
}

export default SelectProgramItem;