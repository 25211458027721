import React from 'react';
import styles from '../../SingleProgram/Programs.module.css';
import LessonsPlansTabContent from './LessonsPlansTabContent'
import ExperimentInstructionsTabContent from './ExperimentInstructionsTabContent'
import { withRouter } from 'react-router-dom'

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';



class PlayMachines1LessonsPlans extends React.Component {

    render() {

        return (
            <div className={styles.FlexContainer}>
               <LessonsPlansTabContent/>
            </div>

        );
    }
}

export default withRouter(PlayMachines1LessonsPlans);