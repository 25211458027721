import React from 'react';
import styles from '../../../screens/SingleProgram/Programs.module.css';
import Lessonlitem from '../Lessonlitem';
import { getUserName } from "../../../components/functions";


import clientConfig from '../../../client-config';
const ProgramsUrl = clientConfig.ProgramsUrl;
const FullPath = `${ProgramsUrl}/progrmas/PLAY_MACHINES_1/SmallPics/MainModels`

const Catapult = `${FullPath}/Catapult.png`;
const DeliveryBox = `${FullPath}/DeliveryBox.png`;
const FlyingChairs = `${FullPath}/FlyingChairs.png`;
const GearboxRideControl = `${FullPath}/GearboxRideControl.png`;
const Mixer = `${FullPath}/Mixer.png`;
const PortableSkyWheel = `${FullPath}/PortableSkyWheel.png`;
const Seesaw = `${FullPath}/Seesaw.png`;
const SlowFastFan = `${FullPath}/SlowFastFan.png`;
const SpinningTopLauncher = `${FullPath}/SpinningTopLauncher.png`;
const TurningBasket = `${FullPath}/TurningBasket.png`;


class ModelInstructionsTabContent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ManuIsOpen: false,
        }
    }

    render() {
        const userName = (getUserName()) ? getUserName() : '';
        const programName = 'PlayMachines1';

        return (
            <div className={styles.FlexContainer}>


                <div className={styles.ItemsContainer}>

                    {/* ITEM TO LOOP FROM DATA BASE */}
                    <Lessonlitem
                        Title="Catapult"
                        LinkTo="https://support.youngengineers.org/portal/kb/articles/lesson-1-catapult"
                        Lesson="Lesson 1"
                        ModelPic={Catapult}
                        TitleBackColor='blue'
                        localStorageItemName='Catapult'
                    // userName={userName}
                    // programName={programName}
                    />
                    {/* ITEM TO LOOP FROM DATA BASE */}



                    {/* ITEM TO LOOP FROM DATA BASE */}
                    <Lessonlitem
                        Title="Flying Chairs"
                        LinkTo="https://support.youngengineers.org/portal/kb/articles/lesson-2-flying-chairs"
                        Lesson="Lesson 2"
                        ModelPic={FlyingChairs}
                        TitleBackColor='blue'
                    />
                    {/* ITEM TO LOOP FROM DATA BASE */}


                    {/* ITEM TO LOOP FROM DATA BASE */}
                    <Lessonlitem
                        Title="Turning Basket"
                        LinkTo="https://support.youngengineers.org/portal/kb/articles/lesson-3-turning-basket"
                        Lesson="Lesson 3"
                        ModelPic={TurningBasket}
                        TitleBackColor='blue'
                    />
                    {/* ITEM TO LOOP FROM DATA BASE */}



                    {/* ITEM TO LOOP FROM DATA BASE */}
                    <Lessonlitem
                        Title="Delivery Box"
                        LinkTo="https://support.youngengineers.org/portal/kb/articles/lesson-4-delivery-box"
                        Lesson="Lesson 4"
                        ModelPic={DeliveryBox}
                        TitleBackColor='blue'
                    />
                    {/* ITEM TO LOOP FROM DATA BASE */}

                    {/* ITEM TO LOOP FROM DATA BASE */}
                    <Lessonlitem
                        Title="Slow Fast Fan"
                        LinkTo="https://support.youngengineers.org/portal/kb/articles/lesson-5-slow-fast-fan"
                        Lesson="Lesson 5"
                        ModelPic={SlowFastFan}
                        TitleBackColor='blue'
                    />
                    {/* ITEM TO LOOP FROM DATA BASE */}


                    {/* ITEM TO LOOP FROM DATA BASE */}
                    <Lessonlitem
                        Title="Spinning Top Launcher"
                        LinkTo="https://support.youngengineers.org/portal/kb/articles/lesson-6-spinning-top-launcher"
                        Lesson="Lesson 6"
                        ModelPic={SpinningTopLauncher}
                        TitleBackColor='blue'
                    />
                    {/* ITEM TO LOOP FROM DATA BASE */}


                    {/* ITEM TO LOOP FROM DATA BASE */}
                    <Lessonlitem
                        Title="Mixer"
                        LinkTo="https://support.youngengineers.org/portal/kb/articles/lesson-7-mixer"
                        Lesson="Lesson 7"
                        ModelPic={Mixer}
                        TitleBackColor='blue'
                    />
                    {/* ITEM TO LOOP FROM DATA BASE */}


                    {/* ITEM TO LOOP FROM DATA BASE */}
                    <Lessonlitem
                        Title="Portable Sky Wheel"
                        LinkTo="https://support.youngengineers.org/portal/kb/articles/lesson-8-portable-sky-wheel-ride"
                        Lesson="Lesson 8"
                        ModelPic={PortableSkyWheel}
                        TitleBackColor='blue'
                    />
                    {/* ITEM TO LOOP FROM DATA BASE */}


                    {/* ITEM TO LOOP FROM DATA BASE */}
                    <Lessonlitem
                        Title="Gearbox Ride Control"
                        LinkTo="https://support.youngengineers.org/portal/kb/articles/lesson-9-gearbox-ride-control"
                        Lesson="Lesson 9"
                        ModelPic={GearboxRideControl}
                        TitleBackColor='blue'
                    />
                    {/* ITEM TO LOOP FROM DATA BASE */}

                    {/* ITEM TO LOOP FROM DATA BASE */}
                    <Lessonlitem
                         Title="Seesaw"
                        LinkTo="https://support.youngengineers.org/portal/kb/articles/lesson-10-seesaw"
                        Lesson="Lesson 10"
                        ModelPic={Seesaw}
                        TitleBackColor='blue'
                    />
                    {/* ITEM TO LOOP FROM DATA BASE */}



                </div>


            </div>
        );
    }
}

export default ModelInstructionsTabContent;