import React from 'react';

import styles from './SelectProgram.module.css';

import { isAdministrator } from "../components/functions";


import SelectProgramItem from './SelectProgramItem';
import { withRouter, Link } from 'react-router-dom'


import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';

import Loader from "../loader.gif";


@inject('Programs')
@observer // REMOVE BRACKETS => @observer
class SelectProgramGrid extends React.Component {



    render() {
     
        // const Plm1 = this.props.Programs.Plm1.map((program) =>

        //     <div className={styles.Item}>
        //         <Link to={`/select-model/${program.metadata.program_name}/`}>
        //             <SelectProgramItem
        //                 TopLegoImage={program.lego_long}
        //                 Title={program.metadata.title}
        //                 TopPosition='-7px'
        //                 ProgramLogo={program.program_logo}
        //             />
        //         </Link>
        //     </div>

        // );



        // const Plm2 = this.props.Programs.Plm2.map((program) =>

        //     <div className={styles.Item}>
        //         <Link to={`/select-model/${program.metadata.program_name}/`}>
        //             <SelectProgramItem
        //                 TopLegoImage={program.lego_long}
        //                 Title={program.metadata.title}
        //                 TopPosition='-7px'
        //                 ProgramLogo={program.program_logo}
        //             />
        //         </Link>
        //     </div>

        // );


        const AllPrograms = this.props.Programs.AllPrograms.map((program) =>

            <div className={styles.Item}>
                <Link to={`/select-model/${program.metadata.program_name}/${program.id}`}>
                    <SelectProgramItem
                        TopLegoImage={program.lego_long}
                        Title={program.metadata.title}
                        TopPosition='-7px'
                        ProgramLogo={program.program_logo}
                    />
                </Link>
            </div>

        );


        const FilterHiddenPrograms = this.props.Programs.FilterdHiddenPrograms.map((program) =>

            <div className={styles.Item}>
                <Link to={`/select-model/${program.metadata.program_name}/${program.id}`}>
                    <SelectProgramItem
                        TopLegoImage={program.lego_long}
                        Title={program.metadata.title}
                        TopPosition='-7px'
                        ProgramLogo={program.program_logo}
                    />
                </Link>
            </div>

        );



        return (
            <div className={styles.FlexContainer}>
                <div className={styles.ItemsContainer}>

                    {isAdministrator() ? AllPrograms : FilterHiddenPrograms}


                </div>

                {this.props.Programs.loading && <img className="loader" src={Loader} alt="Loader" />}
            </div>

        );
    }
}

export default withRouter(SelectProgramGrid);