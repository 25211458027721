import React, { Component } from 'react';
import styles from './SignUpNewStudent.module.css';

import "react-datepicker/dist/react-datepicker.css";

import { isLoggedIn, isFranchiseOwner } from "../components/functions";
import Navbar from "../components/NavbarFranchise";
import FromSingUpNewStudent from '../components/FromSingUpNewStudent';

import { useMediaQuery } from 'react-responsive';


import BackgroungUrl from '../images/WhiteBackground.jpg';
import MobileBackgroungUrl from '../images/WhiteBackgroundMobile.jpg';



const Desktop = ({ children }) => {
	const isDesktop = useMediaQuery({ minWidth: 768 })
	return isDesktop ? children : null
}

const Mobile = ({ children }) => {
	const isDesktop = useMediaQuery({ maxWidth: 767 })
	return isDesktop ? children : null
}



class SignUpNewStudent extends Component {


	render() {


		// const BackgroundStyle = {
		// 	backgroundImage: 'url(' + BackgroungUrl + ')',

		// 	//On moble screen
		// 	'@media (max-width: 801px)': {
		// 		backgroundImage: 'url(' + MobileBackgroungUrl + ')',
		// 	}

		// };


		return (

			!isLoggedIn() || !isFranchiseOwner() ? (

				window.location.href = '/'

			) :
				<React.Fragment>
					<Desktop> <div className={styles.BackgroungImg}><img src={BackgroungUrl} alt="young engineers"></img></div> </Desktop>
					<Mobile> <div className={styles.MobileBackgroungImg}><img src={MobileBackgroungUrl} alt="young engineers"></img></div> </Mobile>

					<Navbar />

					<div className={styles.PageConatiner}>

						<FromSingUpNewStudent />

					</div>
				</React.Fragment>
		);


	}
}

export default SignUpNewStudent;
