import React from 'react';
import { Link } from 'react-router-dom'
import styles from './Modelitem.module.css';

import { isLoggedIn, isFranchiseOwner } from "../../components/functions";

import BottomShadow from '../../images/BottomShadow.png'
// https://play.youngengineers.org/shop/wp-content/uploads/2020/06/BottomShadow.png
import { FaPlay } from 'react-icons/fa';




class ModelItem extends React.Component {



    constructor(props) {
        super(props);

        this.state = {
            password: '',
            passwordValu: this.props.password,
            EnterPassword: true,
            triggerShake: '',
            TitleBackColor: this.props.TitleBackColor,

        }
    }


    handleOnChange = (event) => {

        event.preventDefault()
        // console.log(event.target.value)
        if (event.target.value.length <= 4) {
            this.setState({ [event.target.name]: event.target.value });
        }

        if (event.target.value.length == 4 && event.target.value != this.state.passwordValu) {
            this.setState({ triggerShake: 'shake' });

            setTimeout(function () {
                this.setState({ triggerShake: '' });
                // this.setState({  password: ''});
            }.bind(this), 1000);  // wait 1 seconds, then reset 

        }

        if (event.target.value == this.state.passwordValu) {

            //storing array in localStorage
            const GetOpenedPrograms = JSON.parse(localStorage.getItem("OpenedPrograms"));
            GetOpenedPrograms.push(this.props.LessonsId);
            localStorage.setItem("OpenedPrograms", JSON.stringify(GetOpenedPrograms)); //store OpenedPrograms

            // localStorage.setItem('OpenedPrograms',  this.props.LessonsId);

            localStorage.setItem(this.props.localStorageItemName, true);
            this.setState({ EnterPassword: false });
        }
    };



    render() {


        const style = {
            top: this.props.TopPosition,
            position: 'relative',
        };


        const ShowPassword = () => (
            <Link to={`/model_building_instructions/${this.props.programName}/${this.props.ProgramId}/${this.props.LessonsId}`}>
                <div style={BottomColor1} className={styles.BottomAreaFix2020}>
                    <div className={styles.Password}>{this.props.password}</div>
                    <div className={styles.PlayIcon}><FaPlay className={styles.FaPlay} /></div>
                </div>
            </Link>
        )


        const { password } = this.state;
        const triggerShake = this.state.triggerShake;

        let PasswordField = () => (null)


        if (this.state.EnterPassword) {
            PasswordField = () => (
                <>
                    <div style={BottomColor2}>
                        <div className={styles.CodeArea}>
                            <div>Password</div>
                            <input
                                placeholder=""
                                type="number"
                                className="form-input"
                                name="password"
                                value={password}
                                onChange={this.handleOnChange}
                            />
                        </div>
                    </div>

                </>
            )
        }
        else {

            PasswordField = () => (
                <>
                  
                    <Link to={`/model_building_instructions/${this.props.programName}/${this.props.ProgramId}/${this.props.LessonsId}`}>
                        <div style={BottomColor1} className={styles.OpenCodeArea}>
                            <div className={styles.OpenCodeArea}>
                                <div>Building instructions</div>
                                <div className={styles.Arrow}>>></div>
                            </div>
                        </div>
                    </Link>

                    {/* <Link to={`/model_building_instructions/${this.props.programName}/${this.props.LessonsId}`}>
                        <div className={styles.OpenCodeArea}>
                            <div>Building instructions</div>
                            <div className={styles.Arrow}>>></div>
                        </div>
                    </Link> */}
                </>
            )
        }



        const BottomColor1 = {
            backgroundColor: this.props.BottomColor1
        }

        const BottomColor2 = {
            // backgroundColor: this.props.BottomColor2
        }




        return (

            <>

                <div className={styles.Item + ' ' + triggerShake} key={this.props.ItemId}>
                    {/* <Link to={`/model_building_instructions/${this.props.programName}/${this.props.LessonsId}`}> */}
                    <div className={styles.TitleContainer}>
                        <div className={styles.Title + ' ' + this.props.TitleclassName}>{this.props.Title}</div>
                        <img className={styles.TopTitleBackground} src={this.props.TitleBackColor} alt="TopTitleBackground"></img>
                    </div>

                    <div className={styles.ModelPic}>
                        <img className={styles.TopTitleBackground} src={this.props.ModelPic} alt="ModelPic"></img>
                    </div>





                    {isFranchiseOwner() ?
                        (
                            ShowPassword()
                        )
                        : (
                            PasswordField()
                        )}


                    <div className={styles.BottomShadow}>
                        <img className={styles.TopTitleBackground} src={BottomShadow} alt="BottomShadow"></img>
                    </div>
                    {/* </Link> */}
                </div>


            </>

        );
    }
}

export default ModelItem;