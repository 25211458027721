
import React from 'react';
import styles from './SelectModelContent.module.css';
import Modelitem from './ModelitemLessonPlans';

import { withRouter, Link } from 'react-router-dom'

import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';


@inject('Programs', 'Lessons')
class SelectLessonPlansContent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ManuIsOpen: false,
        }
    }

    componentDidMount() {


        this.props.Lessons.UpdateLessons(this.props.match.params.id)
        // this.props.Lessons.FilterMainModel()


        // if (this.props.ProgramName === 'play-machines-1') {
        //     this.props.Lessons.UpdatePlm1()
        // }
        // if (this.props.ProgramName === 'play-machines-2') {
        //     this.props.Lessons.UpdatePlm2()
        // }

    }





    MainModelsLoop() {

        let lessons = []

        lessons = this.props.Lessons.MainModels.map((lesson) =>
        
            <Modelitem
                key={lesson.id}
                Title={lesson.title.rendered}
                TitleBackColor={lesson.single_model_lego_color}
                ModelPic={lesson.small_image}
                ProgramId={this.props.match.params.id}
                password={lesson.metadata.lesson_password}
                programName={lesson.program_name}
                LessonsId={lesson.id}
                LessonPlanTitle={lesson.metadata.lesson_plans_title}
                LessonPlansLink={lesson.metadata.lesson_plans_url}

            />
        );
        return lessons
    }



    ExperimentalModelsLoop() {

        let lessons = []

        lessons = this.props.Lessons.ExperimentalModels.map((lesson) =>
            <Modelitem
                key={lesson.id}
                Title={lesson.title.rendered}
                TitleBackColor={lesson.single_model_lego_color}
                ModelPic={lesson.small_image}
                ProgramId={this.props.match.params.id}
                password={lesson.metadata.lesson_password}
                programName={lesson.program_name}
                LessonsId={lesson.id}

            />
        );
        return lessons
    }




    // ModelsLoop() {

    //     let lessons = []

    //     if (this.props.ProgramName === 'play-machines-1') {

    //         lessons = this.props.Lessons.Plm1.map((lesson) =>
    //             <Modelitem
    //                 key={lesson.id}
    //                 Title={lesson.title.rendered}
    //                 TitleBackColor={lesson.single_model_lego_color}
    //                 ModelPic={lesson.small_image}
    //                 // Number={lesson.metadata.order}
    //                 password={lesson.metadata.lesson_password}
    //                 programName={lesson.program_name}
    //                 LessonsId={lesson.id}
    //                 BottomColor1='#91B508'
    //                 BottomColor2='#8b8c89'
    //             />
    //         );
    //         return lessons
    //     }


    //     if (this.props.ProgramName === 'play-machines-2') {
    //         lessons = this.props.Lessons.Plm2.map((lesson) =>
    //             <Modelitem
    //                 key={lesson.id}
    //                 Title={lesson.title.rendered}
    //                 TitleBackColor={lesson.single_model_lego_color}
    //                 ModelPic={lesson.small_image}
    //                 // Number={lesson.metadata.order}
    //                 password={lesson.metadata.lesson_password}
    //                 programName={lesson.program_name}
    //                 LessonsId={lesson.id}
    //                 BottomColor1='#FF8100'
    //                 BottomColor2='#004374'
    //             />
    //         );
    //         return lessons
    //     }

    // }





    render() {


        const WithTabs = <Tabs className={styles.Tabs} >
            <TabList>
                <Tab>Main Models</Tab>
                <Tab>Experimental Models</Tab>
            </TabList>
            <div className={styles.TabsFlexContainer}>
                <TabPanel className={styles.ItemsContainer}>

                    {this.MainModelsLoop()}

                </TabPanel>
            </div>
            <div className={styles.TabsFlexContainer}>
                <TabPanel className={styles.ItemsContainer}>
                    {this.ExperimentalModelsLoop()}
                </TabPanel>
            </div>
        </Tabs>

        let Models = < div className={styles.ItemsContainer}>
            {this.MainModelsLoop()}
        </div>

        // if (this.props.Lessons.ExperimentalModels.length > 0) {
        //     Models = WithTabs
        // }
        // else
        //     Models = < div className={styles.ItemsContainer}>
        //         {this.MainModelsLoop()}
        //     </div>

        return (
            <div className={styles.FlexContainer}>

                {this.props.Lessons.loading ? null : Models}

            </div>
        );
    }
}

export default withRouter(SelectLessonPlansContent);